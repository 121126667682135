import * as React from "react"

const Dollar = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.73 47.73" {...props}>
    <path
      d="M23.57 40.22v-3.01c-2.22-.09-3.88-.8-4.98-2.14-1.1-1.34-1.69-3.33-1.78-5.98l4.56-.78c.04 1.08.16 1.98.34 2.7.18.72.46 1.26.82 1.62.37.36.84.53 1.42.53.67 0 1.13-.22 1.38-.65.25-.43.37-.93.37-1.49 0-1.1-.25-2.02-.76-2.77-.51-.74-1.2-1.49-2.09-2.25l-2.75-2.36c-.63-.58-1.19-1.17-1.68-1.78-.5-.6-.89-1.29-1.18-2.07-.29-.78-.44-1.69-.44-2.75 0-2.03.58-3.61 1.73-4.74 1.15-1.13 2.7-1.77 4.64-1.92V7.5h1.55v2.88c1.16.06 2.12.33 2.86.79.74.46 1.31 1.05 1.7 1.75a8 8 0 0 1 .84 2.3c.17.83.28 1.63.32 2.41l-4.63.68c-.02-.8-.09-1.5-.19-2.12-.11-.61-.3-1.09-.58-1.44-.28-.35-.71-.51-1.29-.49-.6.02-1.06.26-1.37.71-.31.45-.47.96-.47 1.52 0 .99.24 1.79.71 2.39.47.6 1.01 1.19 1.62 1.75l2.69 2.33c.71.65 1.37 1.35 1.97 2.1.6.75 1.09 1.59 1.46 2.51.37.92.55 1.97.55 3.15s-.23 2.18-.7 3.12c-.46.94-1.13 1.7-2.01 2.28-.87.58-1.9.93-3.09 1.03v3.04h-1.55Z"
      fill="#87ca94"
    />
    <path
      d="M23.87 47.73C10.71 47.73 0 37.03 0 23.87S10.71 0 23.87 0s23.87 10.71 23.87 23.87-10.71 23.87-23.87 23.87Zm0-45.48c-11.92 0-21.62 9.7-21.62 21.62s9.7 21.61 21.61 21.61 21.61-9.7 21.61-21.61-9.69-21.62-21.6-21.62Z"
      fill="#87ca94"
    />
  </svg>
)

export default Dollar
