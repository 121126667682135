// import getBlockchain from '../connect/ethereum.js';
import React, { Component } from 'react'
import BigNumber from 'bignumber.js'
// import People from "../icon/People";
import DollarDarkBlue from '../icon/card/DollarDarkBlue'
import UpPay from './../icon/card/UpPay.svg'
// import Web3 from 'web3';
import Axios from 'axios'
import { toHaveStyle } from '@testing-library/jest-dom/dist/matchers'
// import { Link } from 'react-router-dom';
class Deposit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 0,
      user: null,
      provider: null,
      balanceUSDT: null,
      blockId: null,
      payment: null,
      usdt: null,
      USDTContract: null,
      airdrop: null,
      accounts: null,
      server: null,
      w3: null,
      color: {
        darkBlue: '#0d2199',
        blue: '#2b398d',
        darkRed: '#bf2228',
        red: '#d22646',
        green: '#6fcd84',
        greenBlue: '#1ca1b6',
        font: '#72878d',
        fontBlue: '#2b398d',
        already: '#bababa',
      },
    }
  }
  componentDidUpdate() {
    if (this.props.accounts !== undefined) {
      if (this.state.accounts !== this.props.accounts) {
        this.setState({ accounts: this.props.accounts })
      }
    }
    if (this.props.payment !== undefined) {
      if (this.state.payment !== this.props.payment) {
        this.setState({ payment: this.props.payment })
      }
    }
    if (this.props.usdt !== undefined) {
      if (this.state.usdt !== this.props.usdt) {
        this.setState({ usdt: this.props.usdt })
      }
    }
    if (this.props.USDTContract !== undefined) {
      if (this.state.USDTContract !== this.props.USDTContract) {
        this.setState({ USDTContract: this.props.USDTContract })
      }
    }
    if (this.props.airdrop !== undefined) {
      if (this.state.airdrop !== this.props.airdrop) {
        this.setState({ airdrop: this.props.airdrop })
      }
    }
    if (this.props.user !== undefined) {
      if (this.state.user !== this.props.user) {
        this.setState({ user: this.props.user })
      }
    }
    if (this.props.server !== undefined) {
      if (this.state.server !== this.props.server) {
        this.setState({ server: this.props.server })
      }
    }
    if (this.props.provider !== undefined) {
      if (this.state.provider !== this.props.provider) {
        this.setState({ provider: this.props.provider })
      }
    }
    if (this.props.balanceUSDT !== undefined) {
      if (this.props.balanceUSDT !== null) {
        if (this.state.balanceUSDT !== this.props.balanceUSDT) {
          this.setState({ balanceUSDT: this.props.balanceUSDT })
        }
      }
    }
    if (this.props.w3 !== undefined) {
      if (this.props.w3 !== null) {
        if (this.state.w3 !== this.props.w3) {
          this.setState({ w3: this.props.w3 })
        }
      }
    }
  }
  checkDot = (event) => {
    if (event.target.value !== '') {
      let balance = parseFloat(
        new BigNumber(this.state.balanceUSDT).dividedBy(Math.pow(10, 18)),
      )
      if (!event.target.value.toString().includes('.')) {
        this.setState({ dot: false })
      }
      if (event.target.value <= balance) {
        if (!event.target.value.toString().includes('.')) {
          this.setState({
            value: parseFloat(event.target.value),
            slideUp: Math.floor(
              100 * (parseFloat(event.target.value) / balance),
            ),
          })
        } else if (event.target.value.split('.')[1].length > 4) {
          this.setState({
            value: parseFloat(event.target.value).toFixed(5).slice(0, -1),
            slideUp: Math.floor(
              100 *
                (parseFloat(event.target.value).toFixed(5).slice(0, -1) /
                  balance),
            ),
          })
        } else {
          this.setState({
            value: event.target.value,
            slideUp: Math.floor(
              100 * (parseFloat(event.target.value) / balance),
            ),
          })
        }
      } else {
        if (balance.toString().includes('.')) {
          this.setState({
            dot: true,
            value: balance.toFixed(5).slice(0, -1),
            slideUp: 100,
          })
        } else {
          this.setState({
            value: balance.toFixed(5).slice(0, -1),
            slideUp: 100,
          })
        }
      }
    } else {
      this.setState({ value: 0, slideUp: 0 })
    }
  }
  validateNumber = (evt) => {
    let key
    var theEvent = evt || window.event
    // Handle paste
    if (theEvent.type === 'paste') {
      key = window.event.clipboardData.getData('text/plain')
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which
      key = String.fromCharCode(key)
    }
    var regex = /[0-9.]/
    // var regex = /[0-9]|\./;
    if (!regex.test(key) || (this.state.dot && key === '.')) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    } else {
      if (!this.state.dot) {
        this.setState({ dot: true })
      }
    }
  }
  setValue(value) {
    if (this.state.balanceUSDT) {
      let balance = parseFloat(
        BigNumber(this.state.balanceUSDT).dividedBy(Math.pow(10, 18)),
      )
      if (balance > value) {
        this.setState({ value: value })
      } else {
        this.setState({ value: parseFloat(balance).toFixed(5).slice(0, -1) })
      }
    } else {
    }
  }
  toPlainString = (num) => {
    return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (
      a,
      b,
      c,
      d,
      e,
    ) {
      return e < 0
        ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
        : b + c + d + Array(e - d.length + 1).join(0)
    })
  }
  deposit = async () => {
    
    if (this.state.value > 0) {
      const data = this.state.value * Math.pow(10, 18)
      let item, g
      var allowance
      try {
        allowance = await this.state.USDTContract.allowance(
          this.state.accounts[0],
          '0x9809763FD2F619050DF2D43e81654017EB919eA3',
        )
        allowance = allowance._hex
        if (
          BigNumber(allowance).isEqualTo(0) ||
          BigNumber(data).isGreaterThan(BigNumber(allowance))
        ) {
          var p = await this.state.USDTContract.approve(
            '0x9809763FD2F619050DF2D43e81654017EB919eA3',
            '9999999999999999999999999999999999999999999999999999999999999999999999999999',
          )
          await p.wait()
        }
        allowance = await this.state.USDTContract.allowance(
          this.state.accounts[0],
          '0x9809763FD2F619050DF2D43e81654017EB919eA3',
        )
        if (BigNumber(data).isLessThanOrEqualTo(BigNumber(allowance._hex))) {
          let max = this.state.user.amountF
          item = await Axios.post(
            this.state.server
              ? 'https://admin.updowndinero.com/api/pending/payment'
              : 'http://127.0.0.1:8000/api/pending/payment',
            {
              id: this.state.id,
              hash: null,
              account: this.state.accounts[0],
              type: 1,
              amount: this.state.value,
              max: max + parseFloat(this.state.value),
            },
          )
          try {
            g = await this.state.airdrop.deposit(
              item.data,
              '0x9809763FD2F619050DF2D43e81654017EB919eA3',
              this.toPlainString(data),
            )
            await g.wait()
            await Axios.post(
              this.state.server
                ? 'https://admin.updowndinero.com/api/payment'
                : 'http://127.0.0.1:8000/api/payment',
              {
                transactionId: item.data,
                id: this.state.user.id,
                hash: g.hash,
                account: this.state.accounts[0],
                type: 1,
              },
            )
          } catch (error) {
            await Axios.post(
              this.state.server
                ? 'https://admin.updowndinero.com/api/payment'
                : 'http://127.0.0.1:8000/api/payment',
              {
                transactionId: item.data,
                id: this.state.user.id,
                hash: g ?? null ? g.hash : null,
                account: this.state.accounts[0],
                type: 1,
              },
            )
            console.log(error)
          }
          this.props.fetchUser()
          this.setState({ value: 0 })
        } else {
          alert('Amount of USDT allowance is not enogh to deposit. Please increse your allowance.')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div
          className="card shadow deposit-card"
          // style={{
          //   marginTop: '10vh',
          //   width: '30%',
          //   height: '50%',
          //   // borderColor: '#2B398D',
          //   // backgroundColor: '#222244',
          // }}
        >
          <div
            className="deposit-margin"
            style={{ color: this.state.color.darkBlue }}
          >
            <div className="deposit-title" style={{ fontWeight: 'bold' }}>
              Deposit
            </div>
            <div className="d-flex">
              <h2 className="deposit-amount">Amout</h2>
              <input
                onChange={(event) => {
                  this.checkDot(event)
                }}
                readOnly={
                  this.state.accounts && this.state.balanceUSDT ? false : true
                }
                onKeyPress={this.validateNumber}
                type="text"
                className="form-control deposit-input"
                style={{
                  borderColor: this.state.color.darkBlue,
                }}
                value={this.state.value}
              />
              <h2 className="deposit-usdt">USDT</h2>
            </div>
            <div className="d-flex justify-content-between deposit-usdt-length">
              <DollarDarkBlue
                className="deposit-dollar"
                style={{ fill: this.state.color.darkBlue }}
              />
              <button
                onClick={() => this.setValue(10)}
                className="btn deposit-button"
                style={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: this.state.color.darkBlue,
                  borderColor: this.state.color.darkBlue,
                }}
              >
                10
              </button>
              <button
                onClick={() => this.setValue(50)}
                className="btn deposit-button"
                style={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: this.state.color.darkBlue,
                  borderColor: this.state.color.darkBlue,
                }}
              >
                50
              </button>
              <button
                onClick={() => this.setValue(100)}
                className="btn deposit-button"
                style={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: this.state.color.darkBlue,
                  borderColor: this.state.color.darkBlue,
                }}
              >
                100
              </button>
              <button
                onClick={() => this.setValue(500)}
                className="btn deposit-button"
                style={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: this.state.color.darkBlue,
                  borderColor: this.state.color.darkBlue,
                }}
              >
                500
              </button>
              <button
                onClick={() => this.setValue(1000)}
                className="btn deposit-button"
                style={{
                  fontWeight: 'bold',
                  color: 'white',
                  backgroundColor: this.state.color.darkBlue,
                  borderColor: this.state.color.darkBlue,
                }}
              >
                1000
              </button>
            </div>
            <div className="deposit-btn-margin d-flex">
              <button
                onClick={async () => {
                  if (
                    (this.state.accounts === null ? [] : this.state.accounts)
                      .length > 0
                  ) {
                    this.deposit()
                  } else {
                    this.props.reConnect()
                  }
                }}
                className="btn text-center deposit-btn-background"
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url('${UpPay}')`,
                }}
              >
                <div
                  className="deposit-btn-font"
                  style={{
                    color: 'white',
                  }}
                >
                  Deposit
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Deposit
