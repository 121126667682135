import * as React from "react"

const Help = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.57 44.57" {...props}>
    <path
      d="M26.06 26.18c-1.6.86-3.38 1-5.36.42-1.7-.48-2.93-1.43-3.69-2.85-.39-.72-.59-1.43-.59-2.14s.05-1.28.15-1.74c.1-.46.27-.93.49-1.42.44-.98 1.04-1.92 1.8-2.85.76-.92 1.3-1.61 1.62-2.07.32-.46.59-.86.79-1.22.37-.65.55-1.13.55-1.46 0-.65-.37-.97-1.1-.97-1.1 0-2.1.41-3 1.22-.33.33-.62.49-.87.49-.52 0-.78-.23-.78-.68 0-.35.22-.72.67-1.08.45-.37.98-.65 1.59-.85.99-.32 2.05-.47 3.18-.47s2.12.13 2.95.4c.84.27 1.55.63 2.15 1.1 1.24 1 1.86 2.23 1.86 3.69s-.38 2.93-1.14 4.42c-.76 1.49-1.59 2.79-2.48 3.91s-1.41 1.81-1.55 2.06c-.14.25-.21.48-.21.68 0 .43.23.65.68.65.62 0 1.13-.15 1.54-.46.65-.49 1.15-.74 1.5-.74s.53.21.53.63-.44.87-1.31 1.35Zm-7.92 6.65c0-1.08.36-1.92 1.07-2.53.72-.61 1.67-.91 2.88-.91s2.15.34 2.85 1.01c.63.63.95 1.45.95 2.45 0 .91-.35 1.67-1.04 2.26-.73.65-1.69.97-2.87.97-1.8 0-2.99-.65-3.57-1.94a3.1 3.1 0 0 1-.27-1.31Z"
      fill="#2b398d"
    />
    <path
      d="M22.29 44.57C10 44.57 0 34.58 0 22.29S10 0 22.29 0s22.29 10 22.29 22.29-10 22.29-22.29 22.29Zm0-42.1c-10.93 0-19.82 8.89-19.82 19.82S11.36 42.1 22.28 42.1s19.81-8.89 19.81-19.81-8.88-19.82-19.8-19.82Z"
      fill="#6fcd84"
    />
  </svg>
)

export default Help
