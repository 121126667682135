import * as React from "react"

const Facebook = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.51 36.64" {...props}>
    <path
      d="M17.89 12.69h-5.44V9.07c.4-3.42 6.05-2.77 6.05-2.77V.42C18.31.37 7.26-2.25 5.06 6.16c0 .02 0 .04-.01.05-.34 1.05-.32 5.87-.32 6.47H0v6.44h5.01v17.51h7.3v-17.5h5.58l.63-6.44h-.63Z"
      fill="#2b398d"
      fillRule="evenodd"
    />
  </svg>
)

export default Facebook
