import React, { Component } from 'react'
import People from './../icon/People'
import Dollar from './../icon/card/Dollar'
import UpPay from './../icon/card/UpPay.svg'
import DownPay from './../icon/card/DownPay.svg'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: null,
      user: null,
      itemOffset: 0,
      history: null,
      server: false,
      color: {
        darkBlue: '#0d2199',
        blue: '#2b398d',
        darkRed: '#bf2228',
        red: '#d22646',
        green: '#6fcd84',
        greenBlue: '#1ca1b6',
        font: '#72878d',
        fontBlue: '#2b398d',
        already: '#bababa',
      },
    }
  }
  // componentDidMount = async () => {
  // }
  componentDidUpdate = async () => {
    if (this.props.user !== undefined) {
      if (this.state.user !== this.props.user) {
        let history = null
        try {
          history = await Axios.get(
            (this.props.server
              ? 'https://admin.updowndinero.com/api/get/history/payment/'
              : 'http://127.0.0.1:8000/api/get/history/payment/') +
              this.props.user.account,
          )
        } catch (e) {
          console.log(e)
        }
        this.setState({
          user: this.props.user,
          history: history ? history.data : null,
          server: this.props.server,
        })
      }
    }
    if (this.props.blockEnd !== undefined) {
      if (this.state.blockEnd !== this.props.blockEnd) {
        let history = null
        try {
          history = await Axios.get(
            (this.props.server
              ? 'https://admin.updowndinero.com/api/get/history/payment/'
              : 'http://127.0.0.1:8000/api/get/history/payment/') +
              this.props.user.account,
          )
        } catch (e) {
          console.log(e)
        }
        this.setState({
          blockEnd: this.props.blockEnd,
          history: history ? history.data : null,
          server: this.props.server,
        })
      }
    }
  }
  handlePageClick = async (event) => {
    let history = await Axios.get(
      (this.state.server
        ? 'https://admin.updowndinero.com/api/get/history/payment/'
        : 'http://127.0.0.1:8000/api/get/history/payment/') +
        this.props.user.account +
        '?page=' +
        (event.selected + 1),
    )
    this.setState({ history: history.data })
  }
  render() {
    return (
      <div
        className="profile-page"
        style={{ backgroundColor: 'black', height: '100vh' }}
      >
        <div
          className="profile-card-margin d-flex justify-content-center"
          style={{ color: this.state.color.darkBlue }}
        >
          <div
            className="d-flex profile-in-card"
            style={{ border: '1px solid #FFFFFF', backgroundColor: '#222244' }}
          >
            <div className="text-center profile-in-card-image">
              <People className="profile-in-card-image-size" />
            </div>
            <div>
              {/* <Dollar style={{ height: '50px', marginTop: '20px', marginRight: '20px' }} />
              <div className="text-center" style={{ marginTop: '10px' }}>
                <p style={{ margin: 0 }}>In game</p>
                <p style={{ margin: 0 }}>{this.state.user ? this.state.user.amount : 'pending'}</p>
                <p style={{ margin: 0 }}>USDT</p>
              </div> */}
              <div className="d-flex text-white profile-fsize">
                <div>wallet: </div>
                <p className="profile-text">
                  {this.state.user
                    ? this.state.user.account
                    : ' not connect...'}
                </p>
              </div>
              <div className="d-flex text-white profile-fsize">
                <div>Balance: </div>
                <p className="profile-text">
                  {this.state.user
                    ? this.state.user.amount + ' USDT'
                    : ' pending...'}
                </p>
              </div>
              <div className="d-flex">
                <div className="profile-f-button">
                  <Link to="/deposit">
                    <button
                      className="btn text-center profile-button"
                      style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url('${UpPay}')`,
                      }}
                    >
                      <div className="profile-button-text">Deposit</div>
                    </button>
                  </Link>
                </div>
                <div className="profile-f-button">
                  <Link to="/withdraw">
                    <button
                      className="btn text-center profile-button "
                      style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundImage: `url('${DownPay}')`,
                      }}
                    >
                      <div className="profile-button-text-danger">Withdraw</div>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ backgroundColor: 'black', paddingBottom: '80px' }}
        >
          <div className="profile-table">
            <div className="row profile-row">
              <div className="col-4">
                <div className="profile-column">History</div>
              </div>
              <div className="col-2 ">
                <div className="profile-column">Status</div>
              </div>
              <div className="col-2 ">
                <div className="profile-column">Amount</div>
              </div>
              <div className="col-2 ">
                <div className="profile-column">profit</div>
              </div>
              <div className="col-2 text-end">
                <div className="profile-column">Balance</div>
              </div>
            </div>
            <div
              style={{ borderBottom: '1px solid #707070', marginBottom: '5px' }}
            ></div>
            {/* test */}
            {/* <div className="row profile-row">
              <div className="col-3">
                <div className="profile-sub-column"> เติมเข้าระบบ</div>
              </div>
              <div className="col-3 ">
                <div className="profile-sub-column"> processing</div>
              </div>
              <div className="col-3 ">
                <div className="profile-sub-column"> 10 USDT</div>
              </div>
              <div className="col-3 text-end">
                <div className="profile-sub-column"> 100 USDT</div>
              </div>
            </div>
            <div style={{ borderBottom: '1px solid #707070', marginBottom: '5px' }}></div>
            <div className="row profile-row">
              <div className="col-3">
                <div className="profile-sub-column"> ถอน</div>
              </div>
              <div className="col-3 ">
                <div className="profile-sub-column"> processing</div>
              </div>
              <div className="col-3 ">
                <div className="profile-sub-column"> 10 USDT</div>
              </div>
              <div className="col-3 text-end">
                <div className="profile-sub-column"> 100 USDT</div>
              </div>
            </div>
            <div style={{ borderBottom: '1px solid #707070', marginBottom: '5px' }}></div> */}
            {/* end test */}
            {this.state.history
              ? this.state.history.data.map((doc, key) => {
                  if (doc.type === 'playment') {
                    if (doc.wtype === 'deposit') {
                      return (
                        <div key={key}>
                          <div key={key} className="row profile-row">
                            <div className="col-4">
                              <div className="profile-sub-column"> Deposit</div>
                            </div>
                            <div className="col-2 ">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.status === 'pending'
                                  ? 'processing'
                                  : doc.status === 'cancel' &&
                                    doc.check_status === 1
                                  ? 'processing'
                                  : doc.status === 'cancel'
                                  ? 'cancel'
                                  : 'complete'}
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.amount} USDT
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div className="profile-sub-column"></div>
                            </div>
                            <div className="col-2 text-end">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.total} USDT
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              borderBottom: '1px solid #707070',
                              marginBottom: '5px',
                            }}
                          ></div>
                        </div>
                      )
                    } else {
                      return (
                        <div key={key}>
                          <div key={key} className="row profile-row">
                            <div className="col-4">
                              <div className="profile-sub-column">
                                {' '}
                                Withdraw
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.status === 'pending'
                                  ? 'processing'
                                  : doc.status === 'cancel' &&
                                    doc.check_status === 1
                                  ? 'processing'
                                  : doc.status === 'cancel'
                                  ? 'cancel'
                                  : 'complete'}
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.amount} USDT
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div className="profile-sub-column"></div>
                            </div>
                            <div className="col-2 text-end">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.total} USDT
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              borderBottom: '1px solid #707070',
                              marginBottom: '5px',
                            }}
                          ></div>
                        </div>
                      )
                    }
                  } else {
                    if (doc.way === 'up') {
                      return (
                        <div key={key}>
                          <div key={key} className="row profile-row">
                            <div className="col-4">
                              <div className="profile-sub-column">
                                {' '}
                                Entered UP R{doc.round_id}
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div
                                className="profile-sub-column"
                                style={{
                                  color: doc.status
                                    ? doc.check_status === 'won'
                                      ? '#80CC82'
                                      : '#AC3234'
                                    : '',
                                }}
                              >
                                {' '}
                                {doc.status ? doc.check_status : 'processing'}
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.amount_origin} USDT
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.amount === 'processing'
                                  ? doc.amount
                                  : doc.check_status === 'won'
                                  ? doc.amount + ' USDT'
                                  : '0 USDT'}
                              </div>
                            </div>
                            <div className="col-2 text-end">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.total} USDT
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              borderBottom: '1px solid #707070',
                              marginBottom: '5px',
                            }}
                          ></div>
                        </div>
                      )
                    } else {
                      return (
                        <div key={key}>
                          <div key={key} className="row profile-row">
                            <div className="col-4">
                              <div className="profile-sub-column">
                                {' '}
                                Entered DOWN R{doc.round_id}
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div
                                className="profile-sub-column"
                                style={{
                                  color: doc.status
                                    ? doc.check_status === 'won'
                                      ? '#80CC82'
                                      : '#AC3234'
                                    : '',
                                }}
                              >
                                {' '}
                                {doc.status ? doc.check_status : 'processing'}
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.amount_origin} USDT
                              </div>
                            </div>
                            <div className="col-2 ">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.amount === 'processing'
                                  ? doc.amount
                                  : doc.check_status === 'won'
                                  ? doc.amount + ' USDT'
                                  : '0 USDT'}
                              </div>
                            </div>
                            <div className="col-2 text-end">
                              <div className="profile-sub-column">
                                {' '}
                                {doc.total} USDT
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              borderBottom: '1px solid #707070',
                              marginBottom: '5px',
                            }}
                          ></div>
                        </div>
                      )
                    }
                  }
                })
              : null}
            <div
              className="d-flex justify-content-end"
              style={{ marginRight: '30px' }}
            >
              {this.state.history ? (
                this.state.history.last_page > 1 ? (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={this.handlePageClick}
                    className="pagination"
                    pageClassName="page-item"
                    activeClassName="page-item active"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    nextClassName="page-item"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    disabledClassName="page-item disabled"
                    breakClassName="page-break"
                    breakLinkClassName="page-link"
                    // pageRangeDisplayed={5}
                    // marginPagesDisplayed={2}
                    pageCount={this.state.history.last_page}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Profile
