import * as React from "react"

const Telegram = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.9 32.17" {...props}>
    <path
      d="M38.9 2.39c-.32 2.37-.6 4.75-.95 7.12-.43 2.88-.87 5.77-1.36 8.64-.63 3.69-1.3 7.36-1.99 11.04-.11.58-.35 1.15-.62 1.68-.58 1.11-1.39 1.53-2.59 1.2-.88-.24-1.75-.63-2.51-1.13-3.47-2.29-6.89-4.64-10.33-6.97-.38-.26-.75-.56-1.07-.89-.75-.76-.8-1.47-.19-2.34.26-.36.56-.69.88-1 3.09-2.95 6.19-5.89 9.28-8.84.57-.55 1.09-1.15 1.64-1.72.28-.29.44-.62.15-.97-.29-.35-.68-.27-1-.07-1.08.67-2.16 1.35-3.22 2.06-4 2.67-7.99 5.35-11.99 8.03-1.56 1.04-3.22 1.57-5.1 1.05-.82-.22-1.65-.38-2.46-.62-1.42-.43-2.82-.91-4.22-1.38-.23-.08-.46-.2-.67-.34-.72-.49-.8-1.24-.12-1.79.53-.43 1.14-.81 1.77-1.08 9.8-4.19 19.6-8.36 29.4-12.5 1.27-.54 2.6-.95 3.93-1.37.48-.15 1.02-.2 1.53-.2 1.08 0 1.64.56 1.71 1.62.02.25.03.5.04.75h.06Z"
      fill="#2b398d"
    />
  </svg>
)

export default Telegram
