// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react'

let tvScriptLoadingPromise

export default function TradingViewWidget() {
  const onLoadScriptRef = useRef()
  const { innerWidth, innerHeight } = window
  useEffect(() => {
    onLoadScriptRef.current = createWidget

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement('script')
        script.id = 'tradingview-widget-loading-script'
        script.src = 'https://s3.tradingview.com/tv.js'
        script.type = 'text/javascript'
        script.onload = resolve

        document.head.appendChild(script)
      })
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current(),
    )

    return () => (onLoadScriptRef.current = null)

    function createWidget() {
      if (
        document.getElementById('tradingview_23517') &&
        'TradingView' in window
      ) {
        let width = 1200
        let height = 620
        if (innerWidth > 1229) {
          width = 1200
          height = 620
        } else if (innerWidth <= 1229 && innerWidth > 1023) {
          width = 800
          height = 470
        } else if (innerWidth <= 1023 && innerWidth > 767) {
          width = 700
          height = 400
        } else if (innerWidth <= 767 && innerWidth > 424) {
          width = 400
          height = 350
        } else if (innerWidth <= 424 && innerWidth > 374) {
          width = 350
          height = 350
        } else if (innerWidth <= 374 && innerWidth > 319) {
          width = 280
          height = 280
        }
        new window.TradingView.widget({
          // autosize: true,
          width: width,
          height: height,
          symbol: 'BINANCE:BTCUSDT',
          interval: '5',
          timezone: 'Asia/Bangkok',
          theme: 'dark',
          style: '1',
          locale: 'en',
          toolbar_bg: '#f1f3f6',
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: 'tradingview_23517',
        })
      }
    }
  }, [])

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_23517" />
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/symbols/BTCUSDT/?exchange=BINANCE"
          rel="noopener"
          target="_blank"
        >
          <span className="blue-text">BTCUSDT chart</span>
        </a>{' '}
        by TradingView
      </div>
    </div>
  )
}
