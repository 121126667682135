import React, { Component } from 'react'
import People from './../icon/People'
import Dollar from './../icon/card/Dollar'
import UpPay from './../icon/card/UpPay.svg'
import DownPay from './../icon/card/DownPay.svg'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import Matamask from './../image/metamask.png'
import State2 from './../image/2.png'
import State3 from './../image/3.png'
import State4 from './../image/4.png'
import State5 from './../image/5.png'
import State6 from './../image/6.png'
import State7 from './../image/7.png'
import State8 from './../image/8.png'
import State9 from './../image/9.png'
import State10 from './../image/10.png'
import State11 from './../image/11.png'
import State12 from './../image/12.png'
import State13 from './../image/13.png'
import State14 from './../image/14.png'
class HowToPlay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: null,
      user: null,
      itemOffset: 0,
      history: null,
      server: false,
      color: {
        darkBlue: '#0d2199',
        blue: '#2b398d',
        darkRed: '#bf2228',
        red: '#d22646',
        green: '#6fcd84',
        greenBlue: '#1ca1b6',
        font: '#72878d',
        fontBlue: '#2b398d',
        already: '#bababa',
      },
    }
  }
  // componentDidMount = async () => {
  // }
  componentDidUpdate = async () => {}
  render() {
    return (
      <div
        className="how-container"
        style={{
          // backgroundColor: 'black',
          height: 'auto',
        }}
      >
        <div className="row">
          <div className="col-12">
            <div
              className="how-main-text"
              style={{
                color: 'white',
              }}
            >
              How to play
            </div>
          </div>
          <div className="col-2 d-flex justify-content-center">
            <div
              className="how-circle"
              style={{
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
            >
              <div
                className="how-circle-text"
                style={{
                  color: this.state.color.darkBlue,
                }}
              >
                1
              </div>
            </div>
          </div>
          <div className="col-10">
            <div
              className="how1-margin how1-text"
              style={{
                color: 'white',
              }}
            >
              Download & create Metamask wallet account from&nbsp;
              <a
                style={{ color: 'white', textDecoration: 'none' }}
                href="https://metamask.io/"
                target="_blank"
                rel="noreferrer"
              >
                https://metamask.io/
              </a>
              <img
                src={Matamask}
                alt=""
                style={{ width: '2vw', height: '2vw' }}
              />
              <br></br>
              How to create step by step:<br></br>(English Ver.)&nbsp;
              <a
                style={{ color: 'white', textDecoration: 'none' }}
                href="https://www.youtube.com/watch?v=A7sbpFvkEe0"
                target="_blank"
                rel="noreferrer"
              >
                https://www.youtube.com/watch?v=A7sbpFvkEe0
              </a>
              <br></br>(Thai Ver.)&nbsp;
              <a
                style={{ color: 'white', textDecoration: 'none' }}
                href="https://www.youtube.com/watch?v=ITdHSjX3r2c"
                target="_blank"
                rel="noreferrer"
              >
                https://www.youtube.com/watch?v=ITdHSjX3r2c
              </a>
              <br></br>
              (Vietnam Ver.)&nbsp;
              <a
                style={{ color: 'white', textDecoration: 'none' }}
                href="https://www.youtube.com/watch?v=hlCnt8ffet0"
                target="_blank"
                rel="noreferrer"
              >
                https://www.youtube.com/watch?v=hlCnt8ffet0
              </a>
              <br></br>(Spain Ver.)&nbsp;
              <a
                style={{ color: 'white', textDecoration: 'none' }}
                href="https://www.youtube.com/watch?v=ocqT7qOVoOM"
                target="_blank"
                rel="noreferrer"
              >
                https://www.youtube.com/watch?v=ocqT7qOVoOM
              </a>
            </div>
          </div>
          <div className="col-2 d-flex justify-content-center">
            <div
              className="how-circle"
              style={{
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
            >
              <div
                className="how-circle-text"
                style={{
                  color: this.state.color.darkBlue,
                }}
              >
                2
              </div>
            </div>
          </div>
          <div className="col-10 how1-margin">
            <img className="how2-image" src={State2} alt=""></img>
          </div>
          <div className="col-2 d-flex justify-content-center">
            <div
              className="how-circle"
              style={{
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
            >
              <div
                className="how-circle-text"
                style={{
                  color: this.state.color.darkBlue,
                }}
              >
                3
              </div>
            </div>
          </div>
          <div className="col-10 how1-margin">
            <img src={State3} alt="" className="how3-image"></img>
            <div className="d-flex ">
              <div className="how1-text" style={{ color: 'white' }}>
                Click profile and click
              </div>
              &nbsp;&nbsp;&nbsp;
              <img src={State4} alt="" className="how3-image2"></img>
            </div>
            <img src={State5} className="mt-2 how3-image3" alt=""></img>
          </div>
          <div className="col-2 d-flex justify-content-center">
            <div
              className="how-circle"
              style={{
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
            >
              <div
                className="how-circle-text"
                style={{
                  color: this.state.color.darkBlue,
                }}
              >
                4
              </div>
            </div>
          </div>
          <div className="col-10 how1-margin">
            <img src={State6} alt="" className="how4-image"></img>
            <div className="how1-text" style={{ color: 'white' }}>
              Input USDT$ amount and click Deposit USDT$ from your MetaMask
              wallet to game wallet.&nbsp;(You must have USDT$ coins in MetaMask
              wallet.)
            </div>
          </div>
          <div className="col-2 d-flex justify-content-center">
            <div
              className="how-circle"
              style={{
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
            >
              <div
                className="how-circle-text"
                style={{
                  color: this.state.color.darkBlue,
                }}
              >
                5
              </div>
            </div>
          </div>
          <div className="col-10 how1-margin">
            <img src={State7} alt="" className="how4-image"></img>
            <div className="mt-2 mb-2 how1-text" style={{ color: 'white' }}>
              Click to choose UP or DOWN.
            </div>
            <div className="d-flex">
              <img src={State8} alt="" className="how4-image"></img>
              <img src={State9} alt="" className="how4-image how5-margin"></img>
            </div>
            <div className="mt-2 how1-text" style={{ color: 'white' }}>
              Input any amount you want to play and click Enter Amount. Then,
              waiting for the result. You can check BTC$ price in real time from
              Binance.
            </div>
          </div>
          <div className="col-2 d-flex justify-content-center">
            <div
              className="how-circle"
              style={{
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
            >
              <div
                className="how-circle-text"
                style={{
                  color: this.state.color.darkBlue,
                }}
              >
                6
              </div>
            </div>
          </div>
          <div className="col-10 how1-margin">
            <div className="d-flex">
              <img src={State10} alt="" className="how6-image"></img>
              <img
                src={State11}
                alt=""
                className="how6-image how5-margin"
              ></img>
            </div>
            <div className="mt-2 how1-text" style={{ color: 'white' }}>
              Check the result. If you won, you will see the icon{' '}
              <img src={State12} alt="" /> on the board that you play, and USDT$
              prize you’ve got.
            </div>
          </div>
          <div className="col-2 d-flex justify-content-center">
            <div
              className="how-circle"
              style={{
                backgroundColor: 'white',
                borderRadius: '100px',
              }}
            >
              <div
                className="how-circle-text"
                style={{
                  color: this.state.color.darkBlue,
                }}
              >
                7
              </div>
            </div>
          </div>
          <div className="col-10 how1-margin">
            <img src={State13} alt="" className="how3-image3"></img>
            <img src={State14} alt="" className="how4-image"></img>
            <div className="mt-2 how1-text" style={{ color: 'white' }}>
              You can withdraw any amount at any time. (You must have USDT$ in
              game wallet before withdrawal.)
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HowToPlay
