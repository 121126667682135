//import useState hook to create menu collapse state
import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import UpPay from './../icon/card/UpPay.svg'
import DownPay from './../icon/card/DownPay.svg'
import MainIcon from './../icon/MainIcon'
import Facebook from './../icon/Facebook'
import Twitter from './../icon/Twitter'
import Telegram from './../icon/Telegram'
import Discord from './../icon/Discord'
import Help from './../icon/Help'
import Language from './../icon/Language'
import People from './../icon/People'
import Dollar from './../icon/card/Dollar'
// import getBlockchain from './../connect/ethereum.js';
// import Web3 from 'web3';
// import detectEthereumProvider from '@metamask/detect-provider';
class NavbarRoute extends React.Component {
  constructor(props) {
    super(props)
    const { innerWidth } = window
    this.state = {
      payment: null,
      usdt: null,
      USDTContract: null,
      airdrop: null,
      accounts: null,
      balanceUSDT: 0,
      user: null,
      mobile: innerWidth < 576.01 ? true : false,
    }
  }
  componentDidUpdate() {
    if (this.props.accounts !== undefined) {
      if (this.state.accounts !== this.props.accounts) {
        this.setState({ accounts: this.props.accounts })
      }
    }
    if (this.props.user !== undefined) {
      if (this.state.user !== this.props.user) {
        this.setState({ user: this.props.user })
      }
    }
    if (this.props.w3 !== undefined) {
      if (this.props.balanceUSDT !== undefined) {
        if (this.props.balanceUSDT !== null) {
          if (
            this.state.balanceUSDT !==
            parseFloat(
              BigNumber(this.props.balanceUSDT)
                .dividedBy(Math.pow(10, 18))
                .toFixed(2),
            )
          ) {
            this.setState({
              balanceUSDT: parseFloat(
                BigNumber(this.props.balanceUSDT)
                  .dividedBy(Math.pow(10, 18))
                  .toFixed(2),
              ),
            })
          }
        }
      }
    }
    if (this.props.mobile !== undefined) {
      if (this.state.mobile !== this.props.mobile) {
        this.setState({ mobile: this.props.mobile })
      }
    }
  }

  render() {
    return (
      <>
        <div className="d-flex justify-content-between nav-top-background">
          <div className="d-flex">
            <Link className="nav-logo-click" to="/">
              <MainIcon className="nav-logo-icon" />
            </Link>
            <div className="nav-group-icon">
              <a href="https://www.facebook.com/updowndinero/">
                <Facebook className="nav-group-icon-size ma-r-3" />
              </a>
              <a href="https://twitter.com/UpdownDinero">
                <Twitter className="nav-group-icon-size ma-r-2" />
              </a>
                <a href="https://t.me/+S31FmD5cxfQ3MjU1">
                  <Telegram className="nav-group-icon-size ma-r-3" />
                </a>
                <a href="discord.gg/wm8KQ2n5XD">
                  <Discord className="nav-group-icon-size" />
                </a>
              
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex nav-right-group">
              <Help className="ma-r-3 nav-right-group-size" />
              <Language className="ma-r-2 nav-right-group-size" />
              <div className="nav-right-profile-group">
                <Link to="/profile">
                  <People className="icon-click ma-r-1 nav-right-profile-group-icon" />
                </Link>
                <Link to="/profile">
                  <button className="btn text-white nav-right-profile-group-button">
                    <div className="nav-right-profile-group-button-text">
                      {this.state.accounts
                        ? this.state.accounts[0]
                        : 'Not Connected'}
                    </div>
                  </button>
                </Link>
              </div>
            </div>
            <div className="d-flex nav-right-price-group">
              <div className="text-center nav-right-in-game-group">
                <Dollar className="nav-group-icon-size" />
                <p className="nav-right-in-game">In game</p>
              </div>
              <div className="text-center nav-right-usdt">
                <span className="nav-right-amount">
                  {this.state.user ? this.state.user.amount : 0}
                </span>
                <p className="nav-right-amount">USDT</p>
              </div>
            </div>
            <div className={this.state.mobile ? 'd-flex ma-r-2' : 'ma-r-2'}>
              <div className="nav-right-button-deposit">
                <Link to="/deposit">
                  <button
                    className="btn text-center nav-right-button-width "
                    style={{
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundImage: `url('${UpPay}')`,
                    }}
                  >
                    <div className="nav-right-button">Deposit</div>
                  </button>
                </Link>
              </div>
              <div className="nav-right-button-withdraw">
                <Link to="/withdraw">
                  <button
                    className="btn text-center nav-right-button-width "
                    style={{
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundImage: `url('${DownPay}')`,
                    }}
                  >
                    <div className="nav-right-button">Withdraw</div>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="font-osw">
          <Outlet />
        </div>
      </>
    )
  }
}
export default NavbarRoute
