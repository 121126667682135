import * as React from "react"

const Language = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.47 44.63" {...props}>
    <path
      d="M29.64 41.56c-.94.36-1.91.65-2.91.87 1.05-.81 2.03-1.79 2.91-2.91v-1.55h-.98c-1.53 2.11-3.39 3.67-5.48 4.57V37.2a4.822 4.822 0 0 1-1.73-2.01v7.35c-2.73-1.17-5.08-3.47-6.8-6.67-.23-.43-.45-.88-.66-1.35 2.4.21 4.86.32 7.33.35-.19-.51-.3-1.07-.3-1.64v-.09a97.46 97.46 0 0 1-7.75-.41c-.99-2.84-1.54-6.05-1.61-9.54h9.36v-.48c0-.43.06-.85.17-1.25h-9.53c.08-3.49.62-6.7 1.61-9.54 2.66-.25 5.41-.4 8.18-.42v9.25c.38-.82.98-1.52 1.73-2.01v-7.23c2.77.03 5.52.17 8.17.42.65 1.86 1.11 3.88 1.37 6.04h1.74c-.24-2.06-.64-4.01-1.22-5.84 2.67.32 5.24.76 7.65 1.31.7 1.46 1.21 2.97 1.55 4.53h1.77c-.85-4.3-2.95-8.25-6.11-11.42C33.88 2.32 28.27 0 22.31 0S10.75 2.32 6.54 6.54C2.32 10.75 0 16.36 0 22.32S2.32 33.88 6.54 38.1c4.22 4.22 9.82 6.54 15.78 6.54 2.53 0 5-.42 7.32-1.23v-1.84Zm7.23-33.8c1.11 1.11 2.09 2.34 2.91 3.66-2.27-.46-4.67-.84-7.19-1.11-.33-.83-.7-1.62-1.1-2.35-1.27-2.36-2.9-4.32-4.77-5.75 3.84.83 7.32 2.74 10.14 5.55ZM23.18 2.1c2.73 1.17 5.08 3.47 6.8 6.68.23.43.45.88.66 1.35-2.44-.21-4.95-.33-7.46-.35V2.1Zm-1.73 7.67c-2.51.02-5.02.14-7.46.35.2-.46.43-.91.66-1.34 1.72-3.2 4.07-5.5 6.8-6.68v7.67ZM7.76 7.76c2.82-2.82 6.3-4.72 10.14-5.55-1.87 1.43-3.5 3.39-4.77 5.75-.4.74-.77 1.53-1.09 2.35-2.5.27-4.91.64-7.19 1.11.83-1.32 1.8-2.55 2.91-3.66Zm-4.02 5.66c2.4-.55 4.97-.99 7.65-1.31-.9 2.83-1.38 5.96-1.45 9.34H1.75c.12-2.81.79-5.5 2-8.03Zm-2 9.76h8.19c.07 3.37.56 6.51 1.45 9.34-2.71-.32-5.28-.76-7.65-1.31a20.452 20.452 0 0 1-2-8.03Zm6.01 13.69c-1.11-1.1-2.08-2.33-2.91-3.66 2.25.46 4.65.83 7.19 1.11.33.82.69 1.61 1.09 2.35 1.27 2.36 2.9 4.32 4.77 5.75-3.84-.84-7.33-2.74-10.14-5.56Z"
      fill="#6fcd84"
    />
    <path
      d="M44.2 20.43H25.77c-1.26 0-2.27 1.02-2.27 2.27v10.52c0 1.26 1.02 2.27 2.27 2.27h6.34v4.94l5.35-4.94h6.74c1.26 0 2.27-1.02 2.27-2.27V22.7c0-1.26-1.02-2.27-2.27-2.27Z"
      fill="#fff"
    />
    <g fill="#2b398d">
      <path d="M33.24 24.78h-4.07v2.19h3.75c.28 0 .48.06.62.19.14.12.2.29.2.49s-.07.37-.2.5-.34.19-.62.19h-3.75v2.54h4.21c.28 0 .5.07.64.2s.22.31.22.53-.07.38-.22.51-.36.2-.64.2h-4.91c-.39 0-.68-.09-.85-.26s-.26-.46-.26-.85v-6.7c0-.26.04-.47.12-.64s.2-.28.36-.36c.16-.07.37-.11.63-.11h4.77c.29 0 .5.06.64.19s.21.29.21.5-.07.38-.21.51-.35.19-.64.19ZM38.03 24.32l3.47 5.25v-5.3c0-.34.07-.6.22-.78.15-.17.35-.26.6-.26s.46.09.61.26c.15.17.23.43.23.78v7.01c0 .78-.32 1.17-.97 1.17-.16 0-.31-.02-.44-.07s-.25-.12-.36-.22c-.11-.1-.22-.22-.32-.36s-.19-.27-.29-.42l-3.39-5.19v5.22c0 .34-.08.6-.24.77-.16.17-.36.26-.61.26s-.46-.09-.61-.26c-.15-.18-.23-.43-.23-.77v-6.88c0-.29.03-.52.1-.69.08-.18.2-.33.38-.45s.37-.17.58-.17c.16 0 .3.03.42.08s.22.12.3.21c.09.09.18.2.27.35.09.14.19.29.28.44Z" />
    </g>
  </svg>
)

export default Language
