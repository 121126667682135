import * as React from "react"

const People = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.2 32.17" {...props}>
    <path
      d="m19.34 20.62-.05-.01c-.13-.03-.25-.06-.38-.08s-1.28-.81-1.28-.81c-.03-.08-.05-.17-.06-.25l-.19-2.07-.05-.53c.14-.16.27-.32.4-.48 1.31-1.66 2.2-3.84 2.26-5.97.03-.28.05-.56.05-.84v-.49c0-5.01.92-9.08-6.93-9.08S6.17 4.06 6.17 9.08v.49c0 .28.02.56.05.84 0 .36.04.71.09 1.07.27 1.77 1.07 3.51 2.16 4.9.12.16.25.31.38.46l-.2 2.58c0 .09-.03.18-.06.26l-1.29.84c-4.14.85-7.31 2.68-7.31 5.49v4.13c0 1.12.97 2.03 2.17 2.03h21.87c1.19 0 2.17-.91 2.17-2.03v-4.13c0-2.7-2.95-4.5-6.86-5.39Z"
      fill="#2b398d"
    />
  </svg>
)

export default People
