import * as React from "react"

const Twitter = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.35 33.61" {...props}>
    <path
      d="M37.12 8.37C37.66 20.3 28.76 33.61 13 33.61c-4.79 0-9.25-1.41-13.01-3.81 4.5.53 8.99-.72 12.56-3.51a8.502 8.502 0 0 1-7.93-5.89c1.33.25 2.64.18 3.83-.15-4.08-.82-6.9-4.5-6.81-8.43 1.15.64 2.45 1.02 3.84 1.06C1.73 10.36.66 5.37 2.88 1.55a24.089 24.089 0 0 0 17.49 8.87C19.13 5.11 23.16 0 28.63 0c2.44 0 4.64 1.03 6.19 2.68 1.93-.38 3.75-1.09 5.39-2.06a8.497 8.497 0 0 1-3.73 4.69c1.72-.2 3.35-.66 4.87-1.34-1.14 1.7-2.57 3.2-4.23 4.39Z"
      fill="#2b398d"
      fillRule="evenodd"
    />
  </svg>
)

export default Twitter
