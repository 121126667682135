import * as React from "react"

const MainIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 61.09 61.95"
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={-12.8}
        y1={43.83}
        x2={69.62}
        y2={37.11}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.36} stopColor="#0d2199" />
        <stop offset={0.97} stopColor="#2b398d" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={-12.75}
        y1={44.51}
        x2={69.67}
        y2={37.8}
        xlinkHref="#a"
      />
      <linearGradient
        id="d"
        x1={-12.67}
        y1={45.47}
        x2={69.75}
        y2={38.76}
        xlinkHref="#a"
      />
      <linearGradient
        id="e"
        x1={-12.59}
        y1={46.45}
        x2={69.83}
        y2={39.74}
        xlinkHref="#a"
      />
      <linearGradient
        id="f"
        x1={-12.51}
        y1={47.35}
        x2={69.91}
        y2={40.64}
        xlinkHref="#a"
      />
      <linearGradient
        id="g"
        x1={-12.45}
        y1={48.17}
        x2={69.97}
        y2={41.45}
        xlinkHref="#a"
      />
      <linearGradient
        id="h"
        x1={-11.7}
        y1={57.33}
        x2={70.72}
        y2={50.61}
        xlinkHref="#a"
      />
      <linearGradient
        id="i"
        x1={-11.62}
        y1={58.29}
        x2={70.8}
        y2={51.57}
        xlinkHref="#a"
      />
      <linearGradient
        id="j"
        x1={-11.57}
        y1={58.98}
        x2={70.85}
        y2={52.27}
        xlinkHref="#a"
      />
      <linearGradient
        id="k"
        x1={-11.5}
        y1={59.84}
        x2={70.92}
        y2={53.13}
        xlinkHref="#a"
      />
      <linearGradient
        id="l"
        x1={-11.42}
        y1={60.75}
        x2={71}
        y2={54.03}
        xlinkHref="#a"
      />
      <linearGradient
        id="m"
        x1={-11.35}
        y1={61.64}
        x2={71.07}
        y2={54.93}
        xlinkHref="#a"
      />
      <linearGradient
        id="b"
        x1={1.31}
        y1={31.26}
        x2={64.52}
        y2={13.21}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ae1a3b" />
        <stop offset={0.36} stopColor="#c31c42" />
        <stop offset={0.67} stopColor="#d21e47" />
      </linearGradient>
      <linearGradient
        id="n"
        x1={-0.38}
        y1={25.34}
        x2={62.82}
        y2={7.28}
        xlinkHref="#b"
      />
      <linearGradient
        id="o"
        x1={2.17}
        y1={34.28}
        x2={65.38}
        y2={16.23}
        xlinkHref="#b"
      />
      <linearGradient
        id="p"
        x1={1.15}
        y1={30.69}
        x2={64.35}
        y2={12.64}
        xlinkHref="#b"
      />
      <linearGradient
        id="q"
        x1={2.15}
        y1={34.19}
        x2={65.35}
        y2={16.13}
        xlinkHref="#b"
      />
      <linearGradient
        id="r"
        x1={1.03}
        y1={30.27}
        x2={64.23}
        y2={12.21}
        xlinkHref="#b"
      />
      <linearGradient
        id="s"
        x1={-0.17}
        y1={26.07}
        x2={63.03}
        y2={8.01}
        xlinkHref="#b"
      />
      <linearGradient
        id="t"
        x1={-1.58}
        y1={21.14}
        x2={61.62}
        y2={3.08}
        xlinkHref="#b"
      />
      <linearGradient
        id="u"
        x1={0}
        y1={26.71}
        x2={63.21}
        y2={8.65}
        xlinkHref="#b"
      />
      <linearGradient
        id="v"
        x1={0.57}
        y1={28.69}
        x2={63.78}
        y2={10.63}
        xlinkHref="#b"
      />
      <linearGradient
        id="w"
        x1={2.4}
        y1={35.08}
        x2={65.6}
        y2={17.02}
        xlinkHref="#b"
      />
      <linearGradient
        id="x"
        x1={2.74}
        y1={36.27}
        x2={65.94}
        y2={18.21}
        xlinkHref="#b"
      />
      <linearGradient
        id="y"
        x1={1.57}
        y1={32.16}
        x2={64.77}
        y2={14.1}
        xlinkHref="#b"
      />
      <linearGradient
        id="z"
        x1={-0.48}
        y1={24.99}
        x2={62.72}
        y2={6.93}
        xlinkHref="#b"
      />
    </defs>
    <path
      d="m7.72 38.54-.04.75v3.61c0 1.19-.34 2.16-1.03 2.91-.7.76-1.63 1.14-2.79 1.14-1.24 0-2.21-.39-2.9-1.17-.64-.71-.97-1.63-.97-2.74v-4.76h3.1v8.15c.31.04.65.07 1.02.07s.75-.07 1.14-.22c.38-.15.72-.38 1.01-.7.63-.68.95-1.69.95-3V39.3c0-.12 0-.26-.03-.43-.02-.17-.03-.28-.03-.34 0-.21.1-.31.3-.31.18 0 .27.11.27.32Z"
      fill="url(#a)"
    />
    <path
      d="m13.68 44.33.54.02c.78 0 1.43-.26 1.93-.77.52-.52.79-1.19.81-2.02.04-1.27-.57-2.13-1.84-2.58-.48-.17-1.09-.25-1.86-.25h-.51v8.11H9.67v-8.55h2.31c1.25 0 2 .02 2.26.05.26.04.53.08.81.14.28.06.58.18.91.37.32.19.6.41.82.68.46.53.67 1.16.66 1.87-.02.91-.29 1.68-.8 2.28-.52.61-1.24.99-2.15 1.14-.27.04-.48.05-.63.05-.36 0-.54-.1-.54-.3 0-.07.03-.13.1-.18.06-.05.15-.07.26-.07Z"
      fill="url(#c)"
    />
    <path
      d="M21.6 38.29h2.51c1.17 0 2.03.08 2.58.25.62.19 1.11.44 1.45.74.34.3.61.61.8.94.39.66.59 1.4.59 2.24 0 1.31-.47 2.38-1.41 3.19-.67.58-1.46.94-2.36 1.08-.5.08-1.21.11-2.12.11H21.6v-8.55Zm3.08 8.05H25c1.09 0 2.02-.34 2.8-1.02.82-.72 1.23-1.67 1.23-2.86 0-.75-.18-1.42-.54-2.02-.52-.87-1.34-1.4-2.47-1.59-.37-.06-.81-.09-1.34-.1v7.59Z"
      fill="url(#d)"
    />
    <path
      d="M32.03 45.75c-.4-.39-.72-.86-.95-1.41-.24-.55-.35-1.16-.35-1.81s.11-1.24.34-1.76c.22-.52.53-.97.93-1.36.85-.81 1.91-1.22 3.18-1.22s2.31.4 3.12 1.2c.82.81 1.23 1.87 1.23 3.17s-.42 2.36-1.26 3.19c-.53.52-1.18.87-1.95 1.07-.38.1-.83.15-1.33.15s-1.02-.1-1.56-.31-1-.51-1.4-.89Zm3.14-7.13c-.22 0-.43.02-.62.05v7.8c.19.03.39.05.62.05 1.08 0 1.98-.36 2.72-1.08.77-.75 1.15-1.71 1.15-2.89s-.36-2.06-1.08-2.8c-.73-.75-1.66-1.12-2.79-1.12Z"
      fill="url(#e)"
    />
    <path
      d="M51.84 38.49c0 .06-.01.14-.04.22-.1.26-.18.49-.26.68l-3.2 7.78-2.28-5.09-2.36 5.09-3.58-8.88h3.31l1.7 4.53 2.3-4.91 2.15 4.9 1.51-3.55c.05-.13.1-.26.13-.39l.1-.37c.04-.18.14-.27.29-.27s.23.09.23.27Z"
      fill="url(#f)"
    />
    <path
      d="m53.65 45.97.02.6c0 .1-.03.19-.08.24s-.12.08-.2.08-.14-.03-.2-.08c-.05-.05-.08-.11-.08-.17s0-.12.02-.18c0-.05.02-.12.03-.18.02-.12.03-.41.03-.88v-7.52l6.57 5.21v-3.6c0-.27 0-.47-.02-.59-.02-.19-.03-.32-.03-.4 0-.2.09-.3.26-.3.07 0 .13.02.2.07.06.05.09.12.09.21v.21l-.02.88v7.58L53.67 42v3.96Z"
      fill="url(#g)"
    />
    <path
      d="M.45 50.84H3.6c1.47 0 2.54.1 3.23.31.78.24 1.38.55 1.81.93s.76.77 1 1.17c.49.82.74 1.75.74 2.8 0 1.64-.59 2.97-1.77 3.99-.84.73-1.82 1.18-2.95 1.35-.63.1-1.51.14-2.64.14H.45V50.84ZM4.3 60.9h.39c1.36 0 2.53-.43 3.5-1.28 1.02-.9 1.53-2.09 1.53-3.57 0-.94-.22-1.78-.67-2.53-.65-1.09-1.68-1.76-3.08-1.99-.46-.08-1.01-.12-1.67-.13v9.49Z"
      fill="url(#h)"
    />
    <path d="M12.25 50.84h3.85v10.69h-3.85V50.84Z" fill="url(#i)" />
    <path
      d="m19.32 60.45.02.76c0 .13-.04.23-.11.3-.07.07-.15.11-.25.11s-.18-.03-.25-.1-.1-.14-.1-.22 0-.15.02-.22.02-.14.03-.23c.03-.15.04-.51.04-1.1v-9.4l8.21 6.51v-4.5c0-.34-.01-.59-.03-.73-.03-.24-.04-.4-.04-.5 0-.25.11-.38.32-.38.09 0 .17.03.25.09.08.06.12.15.12.26v.26l-.03 1.1v9.47l-8.21-6.44v4.95Z"
      fill="url(#j)"
    />
    <path
      d="M36.86 60.88c.26 0 .39.1.39.3 0 .09-.06.17-.17.24-.11.07-.3.11-.57.11h-6.4V50.84h5.95l.62-.02c.38 0 .57.11.57.33 0 .09-.03.16-.09.24s-.13.11-.22.11c-.13 0-.27-.02-.42-.05-.22-.05-.51-.07-.89-.07h-1.67v2.3h1.37l.93-.04c.11 0 .21.03.28.08s.12.13.12.22c0 .21-.14.32-.42.32h-.32c-.17-.02-.3-.03-.42-.03h-1.54v6.74h1.67c.37 0 .64-.02.79-.05.19-.04.33-.05.44-.05Z"
      fill="url(#k)"
    />
    <path
      d="m44.36 57.87.62.05c.89 0 1.64-.31 2.26-.93.62-.62.93-1.39.93-2.31 0-1.21-.53-2.11-1.59-2.69-.58-.32-1.21-.51-1.87-.56-.28-.03-.54-.04-.79-.04h-.79v10.14h-3.85V50.84h4.29c.22 0 .42.01.59.02.17 0 .34.02.49.03.22.02.54.07.94.14.41.08.81.22 1.2.43.4.21.74.47 1.03.79.62.66.93 1.46.93 2.37s-.29 1.71-.86 2.4c-.59.71-1.37 1.17-2.36 1.37.47 1.45 1.07 2.32 1.79 2.6.22.09.44.13.66.13l.6-.14c.19 0 .28.1.28.29 0 .27-.31.4-.94.4-.45 0-.87-.13-1.25-.38-.59-.35-1.09-1.04-1.5-2.05l-.22-.54a.613.613 0 0 0-.09-.16c-.03-.02-.08-.03-.14-.03s-.13 0-.18.02h-.18c-.2 0-.34-.03-.41-.11-.07-.08-.11-.15-.11-.23 0-.22.18-.32.53-.32Z"
      fill="url(#l)"
    />
    <path
      d="M51.73 60.17c-.5-.49-.9-1.08-1.19-1.77-.29-.69-.44-1.45-.44-2.27s.14-1.55.42-2.21c.28-.65.67-1.22 1.16-1.7 1.06-1.02 2.38-1.53 3.98-1.53s2.89.5 3.9 1.5c1.03 1.02 1.54 2.34 1.54 3.96s-.53 2.95-1.58 3.99c-.66.64-1.47 1.09-2.43 1.34-.48.12-1.03.18-1.67.18s-1.28-.13-1.95-.39c-.66-.26-1.25-.63-1.74-1.12Zm3.92-8.91c-.28 0-.54.02-.78.06v9.75c.23.04.49.06.78.06 1.34 0 2.48-.45 3.4-1.34.96-.93 1.44-2.14 1.44-3.61s-.45-2.57-1.35-3.51c-.91-.94-2.08-1.41-3.49-1.41Z"
      fill="url(#m)"
    />
    <path fill="url(#b)" d="M.54 27.44h2.18v7.48H.54z" />
    <path fill="url(#n)" d="M5.66 19.57h-.72v7.87h2.18V19.57H5.66z" />
    <path fill="url(#o)" d="M10.08 26.16h-.74v11.53h2.19V26.16h-1.45z" />
    <path fill="url(#p)" d="M14.04 22.68h-.29v8.2h2.18v-8.2H14.04z" />
    <path fill="url(#q)" d="M18.87 24.6h-.72v9.41h2.19V24.6h-1.47z" />
    <path fill="url(#r)" d="M22.72 20.83h-.16V26.78H24.74V20.83h-2.02z" />
    <path fill="url(#s)" d="M27.39 16.44h-.43v3.13h2.18v-3.13h-1.75z" />
    <path fill="url(#t)" d="M32.12 6.39h-.76v10.05h2.19V6.39H32.12z" />
    <path fill="url(#u)" d="M36.13 11.83h-.36v8.7h2.18v-8.7h-1.82z" />
    <path fill="url(#v)" d="M41.25 14.55h-1.08v5.02h2.19V14.55h-1.11z" />
    <path fill="url(#w)" d="M45.11 19.57h-.54V25.86h2.19V19.57h-1.65z" />
    <path fill="url(#x)" d="M49.88 18.4h-.9V27.09h2.18V18.4h-1.28z" />
    <path fill="url(#y)" d="M53.84 12.02h-.46v10.05h2.19V12.02h-1.73z" />
    <path fill="url(#z)" d="M57.79 0v16.05h2.18V0h-2.18z" />
  </svg>
)

export default MainIcon
