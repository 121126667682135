import * as React from "react"

const Facebook = (props) => (
    
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.05 28.75"{...props}>
    <path d="m8.58,24.04c1.05.5,2.1,1.01,3.19,1.53-.32.54-.63,1.11-.96,1.66-.29.46-.61.9-.9,1.36-.11.18-.23.19-.41.14-3.13-.99-6.02-2.44-8.73-4.28-.54-.36-.7-.78-.71-1.36-.01-1.13-.08-2.26-.05-3.39.08-3.44.74-6.77,1.95-10,.94-2.5,2.18-4.85,3.67-7.06.1-.15.26-.29.43-.37C8.42,1.21,10.88.45,13.44.01c.09-.01.24.08.29.16.27.51.53,1.02.77,1.54.1.22.2.29.46.25,1.84-.26,3.7-.36,5.56-.23.89.06,1.77.17,2.66.24.1,0,.25-.11.31-.21.27-.52.52-1.05.78-1.57.08-.17.17-.22.38-.19,2.55.45,4.99,1.21,7.35,2.27.14.06.28.18.37.31,2.46,3.64,4.21,7.58,5.08,11.89.5,2.48.67,4.98.57,7.5-.02.58-.06,1.17-.12,1.75-.01.14-.11.31-.22.39-2.43,1.77-5.06,3.17-7.89,4.18-.43.15-.87.3-1.31.43-.09.02-.25-.03-.3-.1-.63-1-1.25-2-1.87-3-.01-.02,0-.06-.02-.11,1.09-.43,2.16-.91,3.21-1.57-.29-.22-.52-.42-.77-.59-.06-.04-.2,0-.28.04-1.68.75-3.42,1.32-5.24,1.66-1.73.32-3.47.46-5.24.37-2.92-.15-5.71-.84-8.38-2.02-.1-.05-.28-.03-.38.03-.22.14-.42.32-.63.48,0,.04,0,.08,0,.12Zm4.08-4.38c.68,0,1.19-.15,1.72-.49,1.95-1.23,2.37-4.15.85-5.98-1.23-1.48-3.25-1.65-4.7-.39-1.37,1.18-1.72,3.39-.79,5.01.65,1.13,1.59,1.79,2.92,1.85Zm16.16-3.53c0-1.12-.18-1.79-.56-2.45-1.07-1.81-3.38-2.27-4.95-.97-1.65,1.36-1.89,4.05-.51,5.68,1.23,1.45,3.08,1.71,4.52.6,1.04-.8,1.47-1.91,1.5-2.86Z" 
    fill="#2b398d"/>
    </svg>
  
)

export default Facebook
