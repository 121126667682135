// import logo from './../logo.svg';
import './../App.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
// import getBlockchain from './../connect/ethereum.js';
import BigNumber from 'bignumber.js'
import Dollar from './../icon/card/Dollar'
import UpBox from './../icon/card/UpBox.svg'
import DownBox from './../icon/card/DownBox.svg'
import UpPay from './../icon/card/UpPay.svg'
import DownPay from './../icon/card/DownPay.svg'
import React, { Component } from 'react'
import Up from './../icon/price/Up.svg'
import Down from './../icon/price/Down.svg'
import UpNot from './../icon/price/UpNot.svg'
import DownNot from './../icon/price/DownNot.svg'
import DisableUp from './../icon/card/DisableUp.svg'
import DisableDown from './../icon/card/DisableDown.svg'
import MainImage from './../icon/MainImage.svg'
import MainImagePhone from './../icon/MainImagePhone.svg'
import LiveIcon from './../icon/LiveIcon.svg'
import Won from './../icon/Won.svg'
import TradingViewWidget from './chart/Chart.js'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
// import Web3 from 'web3';
import Axios from 'axios'
import { Typography } from '@mui/material'
import { Link, Outlet } from 'react-router-dom'
const PrettoSlider = styled(Slider)({
  color: '#0d2199',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 10,
    fontSize: 16,
    background: 'unset',
    padding: 0,
    width: 28,
    height: 28,
    color: 'white',
    borderRadius: '50% 50% 50% 50%',
    // backgroundColor: '#0d2199',
    backgroundColor: '#212121',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -54%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})
const PrettoGraySlider = styled(Slider)({
  color: '#bababa',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 10,
    fontSize: 16,
    background: 'unset',
    padding: 0,
    width: 28,
    height: 28,
    color: 'white',
    borderRadius: '50% 50% 50% 50%',
    // backgroundColor: '#0d2199',
    backgroundColor: '#212121',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -50%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})
class Index extends Component {
  constructor(props) {
    super(props)
    const { innerWidth, innerHeight } = window
    this.state = {
      user: null,
      show: 0,
      slideUp: 0,
      dot: false,
      payment: null,
      usdt: null,
      USDTContract: null,
      airdrop: null,
      accounts: null,
      balanceUSDT: null,
      valueUp: 0,
      time: 0,
      lastPrice: 0,
      fixPrice: 0,
      once: 0,
      blockEnd: [],
      blockAfter: [],
      timeEnd: '',
      timeBlock: 'Processing...',
      priceChange: 0,
      timeCount: 0,
      setInterval: null,
      payNow: null,
      upMultiplier: 0,
      downMultiplier: 0,
      pool: 0,
      color: {
        darkBlue: '#0d2199',
        blue: '#2b398d',
        darkRed: '#bf2228',
        red: '#d22646',
        green: '#6fcd84',
        greenBlue: '#1ca1b6',
        font: '#72878d',
        fontBlue: '#2b398d',
        already: '#bababa',
      },
      poolCal: 0,
      payNowCal: null,
      server: null,
      switchBet: true,
      timeNext: '',
      timeNext2: '',
      history: null,
      width: innerWidth,
      height: innerHeight,
      mobile: innerWidth < 576.01 ? true : false,
      // slideSwiper: false,
    }
    this.wrapperRef = []
    this.swiperRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
    var interval = setInterval(() => {
      if (this.props.state.blockEnd !== undefined) {
        if (this.state.blockEnd !== this.props.state.blockEnd) {
          this.setState({ show: 0, valueUp: 0, slideUp: 0 })
        }
      }
      if (this.props.state.accounts !== undefined) {
        if (this.state.accounts !== this.props.state.accounts) {
          this.setState({ show: 0, valueUp: 0, slideUp: 0 })
        }
      }
      if (this.props.state.timeBlock !== undefined) {
        if (this.props.state.timeBlock === 'Processing...') {
          this.setState({ show: 0, valueUp: 0, slideUp: 0 })
        }
      }
      // console.log(this.props.state.poolCal, this.props.state.payNowCal);
      this.setState({
        width: this.props.state.width,
        payment: this.props.state.payment,
        usdt: this.props.state.usdt,
        USDTContract: this.props.state.USDTContract,
        airdrop: this.props.state.airdrop,
        accounts: this.props.state.accounts,
        balanceUSDT: this.props.state.balanceUSDT,
        lastPrice: this.props.state.lastPrice ? this.props.state.lastPrice : 0,
        fixPrice: this.props.state.fixPrice,
        blockEnd: this.props.state.blockEnd,
        blockAfter: this.props.state.blockAfter,
        timeEnd: this.props.state.timeEnd,
        priceChange: this.props.state.priceChange,
        timeCount: this.props.state.timeCount,
        user: this.props.state.user,
        pool: this.props.state.pool,
        poolCal: this.props.state.poolCal,
        payNow: this.props.state.payNow,
        payNowCal: this.props.state.payNowCal,
        click: 1,
        upMultiplier: this.props.state.upMultiplier,
        downMultiplier: this.props.state.downMultiplier,
        server: this.props.state.server,
        timeBlock: this.props.state.timeBlock,
        timeNext: this.props.state.timeNext,
        timeNext2: this.props.state.timeNext2,
        history: this.props.state.history,
        maxInAccount: this.props.user
          ? this.props.user.amount * Math.pow(10, 18)
          : 0,
        mobile: this.props.state.mobile,
      })
    }, 1000)
    this.setState({ setInterval: interval })
  }
  componentWillUnmount = () => {
    clearInterval(this.state.setInterval)
  }
  validateNumber = (evt) => {
    let key
    var theEvent = evt || window.event
    // Handle paste
    if (theEvent.type === 'paste') {
      key = window.event.clipboardData.getData('text/plain')
    } else {
      // Handle key press
      key = theEvent.keyCode || theEvent.which
      key = String.fromCharCode(key)
    }
    var regex = /[0-9.]/
    // var regex = /[0-9]|\./;
    if (!regex.test(key) || (this.state.dot && key === '.')) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    } else {
      if (!this.state.dot) {
        this.setState({ dot: true })
      }
    }
  }
  checkDot = (event) => {
    if (event.target.value !== '') {
      let balance = parseFloat(
        BigNumber(this.state.maxInAccount).dividedBy(Math.pow(10, 18)),
      )
      if (!event.target.value.toString().includes('.')) {
        this.setState({ dot: false })
      }
      if (event.target.value <= balance) {
        if (!event.target.value.toString().includes('.')) {
          this.setState({
            valueUp: parseFloat(event.target.value),
            slideUp: Math.floor(
              100 * (parseFloat(event.target.value) / balance),
            ),
          })
        } else if (event.target.value.split('.')[1].length > 4) {
          this.setState({
            valueUp: parseFloat(event.target.value).toFixed(5).slice(0, -1),
            slideUp: Math.floor(
              100 *
                (parseFloat(event.target.value).toFixed(5).slice(0, -1) /
                  balance),
            ),
          })
        } else {
          this.setState({
            valueUp: event.target.value,
            slideUp: Math.floor(
              100 * (parseFloat(event.target.value) / balance),
            ),
          })
        }
      } else {
        if (balance.toString().includes('.')) {
          this.setState({
            dot: true,
            valueUp: balance.toFixed(5).slice(0, -1),
            slideUp: 100,
          })
        } else {
          this.setState({
            valueUp: balance.toFixed(5).slice(0, -1),
            slideUp: 100,
          })
        }
      }
    } else {
      this.setState({ valueUp: 0, slideUp: 0 })
    }
  }
  valueLabelFormat = (value) => {
    return `${value}%`
  }
  betWay = async (way) => {
    if (this.state.valueUp > 0) {
      let block = await Axios.get(
        this.state.server
          ? 'https://admin.updowndinero.com/api/get/block'
          : 'http://127.0.0.1:8000/api/get/block',
      )
      if(block.data.end.id!==this.state.blockEnd.id){
        window.location.reload();
      }
      else if (this.state.user) {
        await Axios.post(
          this.state.server
            ? 'https://admin.updowndinero.com/api/bet/way'
            : 'http://127.0.0.1:8000/api/bet/way',
          {
            way: way,
            id: this.state.user.id,
            round_id: this.state.blockAfter.id,
            account: this.state.accounts[0],
            amount: this.state.valueUp,
          },
        )
        this.setState({ show: 0, slideUp: 0, value: 0 })
        this.props.fetchBetWay()
        this.props.fetchUser()
      } else {
        this.props.reConnect()
      }
    } else {
      if (!this.state.user) {
        this.props.reConnect()
      }
    }
  }
  showStateBet = () => {
    switch (this.state.show) {
      // switch (4) {
      case 1:
        return (
          <div
            className="card shadow index-bet"
            style={{ backgroundColor: '#212121' }}
            // style={{ borderColor: '#2B398D', backgroundColor: '#222244' }}
          >
            <div
              className="card-body"
              style={{ backgroundColor: '#212121', borderColor: '#2B398D' }}
            >
              <h4
                className="index-bet-start"
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                Start in {this.state.timeEnd}
              </h4>
              <div className="d-flex">
                <Dollar className="index-bet-dollar" />
                <h5
                  className="index-bet-start"
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Prize pool {this.state.pool} USDT
                </h5>
              </div>
              <div>
                <div className="d-flex justify-content-center">
                  <div
                    className="index-bet-box-up"
                    style={{
                      backgroundImage: `url('${UpPay}')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'auto',
                      color: 'white',
                      position: 'relative',
                    }}
                  >
                    <Button
                      className="index-absolute-back"
                      ref={(ref) => this.wrapperRef.push(ref)}
                      onClick={() => {
                        this.setState({ show: 0 })
                      }}
                      endIcon={
                        <ArrowRightIcon
                          className="index-icon-back"
                          style={{
                            color: 'white',
                          }}
                        />
                      }
                    >
                      <div
                        className="index-fontsize-icon-back"
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                      >
                        Back
                      </div>
                    </Button>
                    <div className="d-flex">
                      <div className="index-bet-main-text">UP</div>
                      <button
                        className="btn text-white index-bet-multiplier-button"
                        style={{
                          backgroundColor: this.state.color.darkBlue,
                          borderColor: this.state.color.darkBlue,
                        }}
                      >
                        {this.state.upMultiplier}xPayout
                      </button>
                    </div>
                    <div className="d-flex mt-4 index-bet-input-mar-top">
                      <div className="index-bet-input-show">
                        <input
                          ref={(ref) => this.wrapperRef.push(ref)}
                          onChange={this.checkDot}
                          type="text"
                          maxLength="15"
                          className="form-control index-bet-input-group-show"
                          value={this.state.valueUp}
                          onKeyPress={this.validateNumber}
                          readOnly={
                            (this.state.accounts === null
                              ? []
                              : this.state.accounts
                            ).length > 0
                              ? false
                              : true
                          }
                        />
                        <span className="index-bet-input-inner-text">USDT</span>
                      </div>
                      <button
                        ref={(ref) => this.wrapperRef.push(ref)}
                        className="btn text-white index-bet-input-button"
                        style={{
                          backgroundColor: this.state.color.darkBlue,
                          borderColor: this.state.color.darkBlue,
                        }}
                        onClick={async () => {
                          if (
                            (this.state.accounts === null
                              ? []
                              : this.state.accounts
                            ).length > 0
                          ) {
                            this.betWay(1)
                          } else {
                            this.props.reConnect()
                          }
                        }}
                      >
                        Enter Amount
                      </button>
                    </div>
                  </div>
                </div>

                <Box className="index-bet-input-line">
                  <PrettoSlider
                    ref={(ref) => this.wrapperRef.push(ref)}
                    aria-label="Default"
                    value={this.state.slideUp}
                    valueLabelFormat={this.valueLabelFormat}
                    valueLabelDisplay="on"
                    onChange={(event, value) => {
                      let balance =
                        parseFloat(
                          BigNumber(this.state.maxInAccount).dividedBy(
                            Math.pow(10, 18),
                          ),
                        ) *
                        (value / 100)
                      this.setState({
                        slideUp: value,
                        valueUp: balance.toFixed(5).slice(0, -1),
                      })
                    }}
                  />
                </Box>
                <div className="d-flex justify-content-between index-bet-input-box-circle">
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.darkBlue }}
                    onClick={() => {
                      let balance =
                        parseFloat(
                          BigNumber(this.state.maxInAccount).dividedBy(
                            Math.pow(10, 18),
                          ),
                        ) * 0.1
                      this.setState({
                        valueUp: balance.toFixed(5).slice(0, -1),
                        slideUp: 10,
                      })
                    }}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      10%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.darkBlue }}
                    onClick={() => {
                      let balance =
                        parseFloat(
                          BigNumber(this.state.maxInAccount).dividedBy(
                            Math.pow(10, 18),
                          ),
                        ) * 0.25
                      this.setState({
                        valueUp: balance.toFixed(5).slice(0, -1),
                        slideUp: 25,
                      })
                    }}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      25%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.darkBlue }}
                    onClick={() => {
                      let balance =
                        parseFloat(
                          BigNumber(this.state.maxInAccount).dividedBy(
                            Math.pow(10, 18),
                          ),
                        ) * 0.5
                      this.setState({
                        valueUp: balance.toFixed(5).slice(0, -1),
                        slideUp: 50,
                      })
                    }}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      50%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.darkBlue }}
                    onClick={() => {
                      let balance =
                        parseFloat(
                          BigNumber(this.state.maxInAccount).dividedBy(
                            Math.pow(10, 18),
                          ),
                        ) * 0.75
                      this.setState({
                        valueUp: balance.toFixed(5).slice(0, -1),
                        slideUp: 75,
                      })
                    }}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      75%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.darkBlue }}
                    onClick={() => {
                      let balance = parseFloat(
                        BigNumber(this.state.maxInAccount).dividedBy(
                          Math.pow(10, 18),
                        ),
                      )
                      this.setState({
                        valueUp: balance.toFixed(5).slice(0, -1),
                        slideUp: 100,
                      })
                    }}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      Max
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      case 2:
        return (
          <div
            className="card shadow index-bet"
            style={{ backgroundColor: '#212121' }}
            // style={{ borderColor: '#2B398D', backgroundColor: '#222244' }}
          >
            <div
              className="card-body"
              style={{ backgroundColor: '#212121', borderColor: '#2B398D' }}
            >
              <h4
                className="index-bet-start"
                style={{ color: 'white', fontWeight: 'bold' }}
              >
                Start in {this.state.timeEnd}
              </h4>
              <div className="d-flex">
                <Dollar className="index-bet-dollar" />
                <h5
                  className="index-bet-start"
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Prize pool {this.state.pool} USDT
                </h5>
              </div>
              <div>
                <div className="d-flex justify-content-center">
                  <div
                    className="index-bet-box-up"
                    style={{
                      backgroundImage: `url('${DownPay}')`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'auto',
                      color: 'white',
                      position: 'relative',
                    }}
                  >
                    <Button
                      className="index-absolute-back"
                      ref={(ref) => this.wrapperRef.push(ref)}
                      onClick={() => {
                        this.setState({ show: 0 })
                      }}
                      endIcon={
                        <ArrowRightIcon
                          className="index-icon-back"
                          style={{
                            color: 'white',
                          }}
                        />
                      }
                    >
                      <div
                        className="index-fontsize-icon-back"
                        style={{
                          fontWeight: 'bold',
                          color: 'white',
                        }}
                      >
                        Back
                      </div>
                    </Button>
                    <div className="d-flex">
                      <div className="index-bet-main-text">DOWN</div>
                      <button
                        className="btn text-white index-bet-multiplier-button"
                        style={{
                          backgroundColor: this.state.color.darkBlue,
                          borderColor: this.state.color.darkBlue,
                        }}
                      >
                        {this.state.upMultiplier}xPayout
                      </button>
                    </div>
                    <div className="d-flex mt-4 index-bet-input-mar-top">
                      <div className="index-bet-input-show">
                        <input
                          ref={(ref) => this.wrapperRef.push(ref)}
                          onChange={this.checkDot}
                          type="text"
                          maxLength="15"
                          className="form-control index-bet-input-group-show"
                          value={this.state.valueUp}
                          onKeyPress={this.validateNumber}
                          readOnly={
                            (this.state.accounts === null
                              ? []
                              : this.state.accounts
                            ).length > 0
                              ? false
                              : true
                          }
                        />
                        <span className="index-bet-input-inner-text">USDT</span>
                      </div>
                      <button
                        ref={(ref) => this.wrapperRef.push(ref)}
                        className="btn text-white index-bet-input-button"
                        style={{
                          backgroundColor: this.state.color.darkBlue,
                          borderColor: this.state.color.darkBlue,
                        }}
                        onClick={async () => {
                          if (
                            (this.state.accounts === null
                              ? []
                              : this.state.accounts
                            ).length > 0
                          ) {
                            this.betWay(2)
                          } else {
                            this.props.reConnect()
                          }
                        }}
                      >
                        Enter Amount
                      </button>
                    </div>
                  </div>
                </div>
                <Box className="index-bet-input-line">
                  <PrettoSlider
                    ref={(ref) => this.wrapperRef.push(ref)}
                    aria-label="Default"
                    value={this.state.slideUp}
                    valueLabelFormat={this.valueLabelFormat}
                    valueLabelDisplay="on"
                    onChange={(event, value) => {
                      let balance =
                        parseFloat(
                          BigNumber(this.state.maxInAccount).dividedBy(
                            Math.pow(10, 18),
                          ),
                        ) *
                        (value / 100)
                      this.setState({
                        slideUp: value,
                        valueUp: balance.toFixed(5).slice(0, -1),
                      })
                    }}
                  />
                </Box>
                <div className="d-flex justify-content-between index-bet-input-box-circle">
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.darkBlue }}
                    onClick={() => {
                      let balance =
                        parseFloat(
                          BigNumber(this.state.maxInAccount).dividedBy(
                            Math.pow(10, 18),
                          ),
                        ) * 0.1
                      this.setState({
                        valueUp: balance.toFixed(5).slice(0, -1),
                        slideUp: 10,
                      })
                    }}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      10%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.darkBlue }}
                    onClick={() => {
                      let balance =
                        parseFloat(
                          BigNumber(this.state.maxInAccount).dividedBy(
                            Math.pow(10, 18),
                          ),
                        ) * 0.25
                      this.setState({
                        valueUp: balance.toFixed(5).slice(0, -1),
                        slideUp: 25,
                      })
                    }}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      25%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.darkBlue }}
                    onClick={() => {
                      let balance =
                        parseFloat(
                          BigNumber(this.state.maxInAccount).dividedBy(
                            Math.pow(10, 18),
                          ),
                        ) * 0.5
                      this.setState({
                        valueUp: balance.toFixed(5).slice(0, -1),
                        slideUp: 50,
                      })
                    }}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      50%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.darkBlue }}
                    onClick={() => {
                      let balance =
                        parseFloat(
                          BigNumber(this.state.maxInAccount).dividedBy(
                            Math.pow(10, 18),
                          ),
                        ) * 0.75
                      this.setState({
                        valueUp: balance.toFixed(5).slice(0, -1),
                        slideUp: 75,
                      })
                    }}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      75%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.darkBlue }}
                    onClick={() => {
                      let balance = parseFloat(
                        BigNumber(this.state.maxInAccount).dividedBy(
                          Math.pow(10, 18),
                        ),
                      )
                      this.setState({
                        valueUp: balance.toFixed(5).slice(0, -1),
                        slideUp: 100,
                      })
                    }}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      Max
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      case 3:
        return (
          <div
            className="card shadow index-bet"
            style={{ backgroundColor: '#212121' }}
            // style={{ borderColor: '#2B398D', backgroundColor: '#222244' }}
          >
            <div
              className="card-body"
              style={{ backgroundColor: '#212121', borderColor: '#2B398D' }}
            >
              <h4
                className="index-bet-start"
                style={{ color: this.state.color.font, fontWeight: 'bold' }}
              >
                Start in {this.state.timeEnd}
              </h4>
              <div className="d-flex">
                <Dollar className="index-bet-dollar" />
                <h5
                  className="index-bet-start"
                  style={{
                    color: this.state.color.fontBlue,
                    fontWeight: 'bold',
                  }}
                >
                  Prize pool {this.state.pool} USDT
                </h5>
              </div>
              <div>
                <div className="d-flex justify-content-center">
                  <div
                    className="index-bet-box-grey"
                    style={{ backgroundColor: this.state.color.already }}
                  >
                    <div className="d-flex">
                      <div className="index-bet-main-text">
                        {this.state.payNow
                          ? this.state.payNow.way === 'up'
                            ? 'UP'
                            : 'DOWN'
                          : null}
                      </div>
                      <button
                        className="btn text-white index-bet-multiplier-button"
                        style={{
                          backgroundColor: this.state.color.darkBlue,
                          borderColor: this.state.color.darkBlue,
                        }}
                      >
                        {this.state.upMultiplier}xPayout
                      </button>
                    </div>
                    <div className="d-flex mt-4 index-bet-input-mar-top">
                      <div className="index-bet-input-show">
                        <input
                          ref={(ref) => this.wrapperRef.push(ref)}
                          onChange={this.checkDot}
                          type="text"
                          maxLength="15"
                          className="form-control index-bet-input-group-show"
                          value={this.state.valueUp}
                          onKeyPress={this.validateNumber}
                          readOnly={
                            (this.state.accounts === null
                              ? []
                              : this.state.accounts
                            ).length > 0
                              ? false
                              : true
                          }
                        />
                        <span className="index-bet-input-inner-text">USDT</span>
                      </div>
                      <button
                        ref={(ref) => this.wrapperRef.push(ref)}
                        className="btn text-white index-bet-input-button"
                        style={{
                          backgroundColor: this.state.color.darkBlue,
                          borderColor: this.state.color.darkBlue,
                        }}
                        onClick={async () => {}}
                      >
                        Enter Amount
                      </button>
                    </div>
                  </div>
                </div>
                <Box className="index-bet-input-line">
                  <PrettoGraySlider
                    ref={(ref) => this.wrapperRef.push(ref)}
                    aria-label="Default"
                    value={this.state.slideUp}
                    valueLabelFormat={this.valueLabelFormat}
                    valueLabelDisplay="on"
                    onChange={(event, value) => {}}
                  />
                </Box>
                <div className="d-flex justify-content-between index-bet-input-box-circle">
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.already }}
                    onClick={() => {}}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      10%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.already }}
                    onClick={() => {}}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      25%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.already }}
                    onClick={() => {}}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      50%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.already }}
                    onClick={() => {}}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      75%
                    </div>
                  </button>
                  <button
                    ref={(ref) => this.wrapperRef.push(ref)}
                    className="btn index-bet-input-circle"
                    style={{ backgroundColor: this.state.color.already }}
                    onClick={() => {}}
                  >
                    <div className="text-white text-center index-bet-input-circle-text">
                      Max
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      default:
        if (this.state.payNow) {
          if (this.state.payNow.way === 'up') {
            return (
              <>
                <div
                  className="card shadow index-bet"
                  style={{ backgroundColor: '#212121' }}
                  // style={{ borderColor: '#2B398D', backgroundColor: '#222244' }}
                >
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: '#212121',
                      borderColor: '#2B398D',
                    }}
                  >
                    <h4
                      className="index-bet-start"
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      Start in {this.state.timeEnd}
                    </h4>
                    <div className="d-flex">
                      <Dollar className="index-bet-dollar" />
                      <h5
                        className="index-bet-start"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                        }}
                      >
                        Prize pool {this.state.pool} USDT
                      </h5>
                    </div>
                    <div>
                      <div className="d-flex justify-content-center">
                        <div
                          className="index-bet-box-up"
                          style={{
                            backgroundImage: `url('${UpPay}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'auto',
                            color: 'white',
                          }}
                        >
                          <div className="d-flex">
                            <div className="index-bet-main-text">UP</div>
                            <button
                              className="btn text-white index-bet-multiplier-button"
                              style={{
                                backgroundColor: this.state.color.darkBlue,
                                borderColor: this.state.color.darkBlue,
                              }}
                            >
                              {this.state.upMultiplier}xPayout
                            </button>
                          </div>
                          <div className="d-flex mt-4 index-bet-input-mar-top">
                            <div className="index-bet-input-show">
                              <input
                                ref={(ref) => this.wrapperRef.push(ref)}
                                onChange={this.checkDot}
                                type="text"
                                maxLength="15"
                                className="form-control index-bet-input-group-show"
                                value={this.state.payNow.amount}
                                readOnly={true}
                              />
                              <span className="index-bet-input-inner-text">
                                USDT
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      className="index-bet-finish-text"
                      style={{ color: '#7ac687' }}
                    >
                      YOU ENTERED UP
                    </p>
                    <div className="d-flex justify-content-end">
                      <div
                        className="index-past-circle"
                        style={{
                          backgroundColor: this.state.color.blue,
                          borderRadius: 100,
                        }}
                      ></div>
                      <div
                        className="index-past-round-number"
                        style={{ color: 'white' }}
                      >
                        {this.state.blockAfter.id}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          } else {
            return (
              <>
                <div
                  className="card shadow index-bet"
                  style={{ backgroundColor: '#212121' }}
                  // style={{ borderColor: '#2B398D', backgroundColor: '#222244' }}
                >
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: '#212121',
                      borderColor: '#2B398D',
                    }}
                  >
                    <h4
                      className="index-bet-start"
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      Start in {this.state.timeEnd}
                    </h4>
                    <div className="d-flex">
                      <Dollar className="index-bet-dollar" />
                      <h5
                        className="index-bet-start"
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                        }}
                      >
                        Prize pool {this.state.pool} USDT
                      </h5>
                    </div>
                    <div>
                      <div className="d-flex justify-content-center">
                        <div
                          className="index-bet-box-up"
                          style={{
                            backgroundImage: `url('${DownPay}')`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'auto',
                            color: 'white',
                          }}
                        >
                          <div className="d-flex">
                            <div className="index-bet-main-text">DOWN</div>
                            <button
                              className="btn text-white index-bet-multiplier-button"
                              style={{
                                backgroundColor: this.state.color.darkBlue,
                                borderColor: this.state.color.darkBlue,
                              }}
                            >
                              {this.state.downMultiplier}xPayout
                            </button>
                          </div>
                          <div className="d-flex mt-4 index-bet-input-mar-top">
                            <div className="index-bet-input-show">
                              <input
                                ref={(ref) => this.wrapperRef.push(ref)}
                                onChange={this.checkDot}
                                type="text"
                                maxLength="15"
                                className="form-control index-bet-input-group-show"
                                value={this.state.payNow.amount}
                                readOnly={true}
                              />
                              <span className="index-bet-input-inner-text">
                                USDT
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      className="index-bet-finish-text"
                      style={{ color: '#d02646' }}
                    >
                      YOU ENTERED DOWN
                    </p>
                    <div className="d-flex justify-content-end">
                      <div
                        className="index-past-circle"
                        style={{
                          backgroundColor: this.state.color.blue,
                          borderRadius: 100,
                        }}
                      ></div>
                      <div
                        className="index-past-round-number"
                        style={{ color: 'white' }}
                      >
                        {this.state.blockAfter.id}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
        } else {
          return (
            <>
              <div
                className="card shadow index-bet"
                style={{ backgroundColor: '#212121' }}
                // style={{ borderColor: '#2B398D', backgroundColor: '#222244' }}
              >
                <div
                  className="card-body"
                  style={{ backgroundColor: '#212121', borderColor: '#2B398D' }}
                >
                  <h4
                    className="index-bet-start"
                    style={{ color: 'white', fontWeight: 'bold' }}
                  >
                    Start in {this.state.timeEnd}
                  </h4>
                  <div className="d-flex">
                    <Dollar className="index-bet-dollar" />
                    <h5
                      className="index-bet-start"
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      Prize pool {this.state.pool} USDT
                    </h5>
                  </div>
                  <div className="d-flex justify-content-center index-bet-updown-mar-top">
                    <div
                      className="index-bet-updown-box-up"
                      style={{
                        backgroundImage: `url('${
                          this.state.timeBlock === 'Processing...'
                            ? DisableUp
                            : UpBox
                        }')`,
                        backgroundSize: 'auto',
                        backgroundRepeat: 'no-repeat',
                      }}
                      onClick={() => {
                        if (this.state.timeBlock !== 'Processing...') {
                          this.setState({
                            show: this.state.payNow === null ? 1 : 3,
                            click: 1,
                          })
                        }
                      }}
                    >
                      <div className="justify-content-center">
                        <div className="text-center index-bet-updown-box-mar-text">
                          <div className="index-bet-updown-box-choose">
                            CHOOSE
                          </div>
                          <div className="index-bet-updown-box-text">UP</div>
                        </div>
                        <div className="text-center" style={{ color: 'white' }}>
                          <button
                            className={
                              'btn btn-primary index-bet-undown-box-button'
                            }
                            onClick={() => {
                              if (this.state.timeBlock !== 'Processing...') {
                                this.setState({
                                  show: this.state.payNow === null ? 1 : 3,
                                  click: 1,
                                })
                              }
                            }}
                            ref={(ref) => this.wrapperRef.push(ref)}
                            style={{
                              backgroundColor:
                                this.state.timeBlock === 'Processing...'
                                  ? this.state.color.already
                                  : this.state.color.darkBlue,
                              borderColor:
                                this.state.timeBlock === 'Processing...'
                                  ? this.state.color.already
                                  : this.state.color.darkBlue,
                            }}
                          >
                            {this.state.upMultiplier}xPayout
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="index-bet-updown-box-down"
                      style={{
                        backgroundImage: `url('${
                          this.state.timeBlock === 'Processing...'
                            ? DisableDown
                            : DownBox
                        }')`,
                        backgroundSize: 'auto',
                        backgroundRepeat: 'no-repeat',
                      }}
                      onClick={() => {
                        if (this.state.timeBlock !== 'Processing...') {
                          this.setState({
                            show: this.state.payNow === null ? 2 : 3,
                            click: 2,
                          })
                        }
                      }}
                    >
                      <div className="justify-content-center">
                        <div className="text-center index-bet-updown-box-mar-text">
                          <div className="index-bet-updown-box-choose">
                            CHOOSE
                          </div>
                          <div className="index-bet-updown-box-text">DOWN</div>
                        </div>
                        <div className="text-center" style={{ color: 'white' }}>
                          <button
                            className={
                              'btn btn-primary index-bet-undown-box-button'
                            }
                            onClick={() => {
                              if (this.state.timeBlock !== 'Processing...') {
                                this.setState({
                                  show: this.state.payNow === null ? 2 : 3,
                                  click: 2,
                                })
                              }
                            }}
                            ref={(ref) => this.wrapperRef.push(ref)}
                            style={{
                              backgroundColor:
                                this.state.timeBlock === 'Processing...'
                                  ? this.state.color.already
                                  : this.state.color.darkBlue,
                              borderColor:
                                this.state.timeBlock === 'Processing...'
                                  ? this.state.color.already
                                  : this.state.color.darkBlue,
                            }}
                          >
                            {this.state.downMultiplier}xPayout
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <div
                      className="index-past-circle"
                      style={{
                        backgroundColor: this.state.color.blue,
                        borderRadius: 100,
                      }}
                    ></div>
                    <div
                      className="index-past-round-number"
                      style={{ color: 'white' }}
                    >
                      {this.state.blockAfter.id}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
    }
  }
  showStatePast = (data) => {
    return (
      <>
        <div
          className="shadow index-past"
          style={{
            backgroundImage: `url('${data.way === 'up' ? UpPay : DownPay}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            color: 'white',
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="index-past-end">End</div>
              <button
                className="btn text-white index-past-button"
                style={{
                  backgroundColor: this.state.color.darkBlue,
                  borderColor: this.state.color.darkBlue,
                }}
              >
                {(data.multiplier ?? '').toFixed(2)}xPayout
              </button>
            </div>
            <div className="d-flex">
              {parseFloat(data.my_reward) > 0 ? (
                <img src={Won} alt="won" className="index-past-icon-won" />
              ) : null}
              {/* <img src={Won} alt='won' className="index-past-icon-won" /> */}
              <div className="index-past-text-way">
                {data.way === 'up' ? 'UP' : 'DOWN'}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between  mt-2">
            <div className="d-flex">
              <Dollar
                className="index-past-dollar"
                style={{ filter: 'brightness(0) invert(1)' }}
              />
              <h2 className="index-text">Prize pool {data.pool} USDT</h2>
            </div>
            <h2
              className="index-text"
              style={{ color: this.state.color.darkBlue }}
            >
              WON {data.my_reward} USDT
            </h2>
          </div>
          <div className="d-flex justify-content-between  margin-price">
            <div>
              <h4 className="index-text-price">Bitcoin Locked Price (BTC)</h4>
              <h2 className="index-text">
                ${data.start_price.toLocaleString()}
              </h2>
            </div>
            <div className="text-end">
              <h4 className="index-text-price">Bitcoin Closed Price (BTC)</h4>
              <h2 className="index-text">${data.end_price.toLocaleString()}</h2>

            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div
              className="index-past-circle"
              style={{
                backgroundColor: this.state.color.blue,
                borderRadius: 100,
              }}
            ></div>
            <div
              className="index-past-round-number"
              style={{ color: this.state.color.blue }}
            >
              {data.id}
            </div>
          </div>
        </div>
      </>
    )
  }
  showStatePastNoData = (data) => {
    return (
      <>
        <div
          className="shadow index-past"
          style={{
            backgroundColor: 'grey',
            color: 'white',
          }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <div className="index-past-end">End</div>
              <button
                className="btn text-white index-past-button"
                style={{
                  backgroundColor: this.state.color.darkBlue,
                  borderColor: this.state.color.darkBlue,
                }}
              >
                ??xPayout
              </button>
            </div>
            <div className="d-flex">
              {/* {parseFloat(data.my_reward) > 0 ? (
                <img src={Won} alt="won" className="index-past-icon-won" />
              ) : null} */}
              {/* <img src={Won} alt='won' className="index-past-icon-won" /> */}
              <div className="index-past-text-way">??</div>
            </div>
          </div>
          <div className="d-flex justify-content-between  mt-2">
            <div className="d-flex">
              <Dollar
                className="index-past-dollar"
                style={{ filter: 'brightness(0) invert(1)' }}
              />
              <h2 className="index-text">Prize pool ?? USDT</h2>
            </div>
            <h2
              className="index-text"
              style={{ color: this.state.color.darkBlue }}
            >
              WON ?? USDT
            </h2>
          </div>
          <div className="d-flex justify-content-between  margin-price">
            <div>
              <h4 className="index-text-price">Bitcoin Locked Price (BTC)</h4>
              <h2 className="index-text">$ ??????</h2>
            </div>
            <div className="text-end">
              <h4 className="index-text-price">Bitcoin Closed Price (BTC)</h4>
              <h2 className="index-text">$ ??????</h2>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div
              className="index-past-circle"
              style={{
                backgroundColor: this.state.color.blue,
                borderRadius: 100,
              }}
            ></div>
            <div
              className="index-past-round-number"
              style={{ color: this.state.color.blue }}
            >
              ??
            </div>
          </div>
        </div>
      </>
    )
  }
  addPositon = (data, position) => {
    // let data = this.state.blockEnd.start_price.toString();
    // if (data.length > 6 && data.length < 10) {
    //   return [
    //     data.slice(0, data.length - position),
    //     ',',
    //     data.slice(data.length - position),
    //   ].join('')
    // } else if (data.length >= 10) {
    //   let result = [
    //     data.slice(0, data.length - 6),
    //     ',',
    //     data.slice(data.length - 6),
    //   ].join('')
    //   return [
    //     result.slice(0, result.length - 10),
    //     ',',
    //     result.slice(result.length - 10),
    //   ].join('')
    // } else {
    //   return this.state.blockEnd.start_price
    // }
  }
  showRun = () => {
    // console.log(this.state.payNowCal)
    return (
      <div
        className="card shadow"
        style={{ backgroundColor: '#212121' }}
        // style={{ borderColor: '#2B398D', backgroundColor: '#222244' }}
      >
        <div
          className="card-body"
          style={{
            backgroundColor: '#212121',
            borderColor: '#2B398D',
            position: 'relative',
          }}
        >
          <img src={LiveIcon} alt="live" className="index-absolute-live" />
          <h4
            className="index-run-text"
            style={{ color: 'white', fontWeight: 'bold' }}
          >
            {' '}
            {this.state.timeBlock === 'Processing...'
              ? this.state.timeBlock
              : ' End in ' + this.state.timeBlock}
          </h4>
          <div className="d-flex">
            <Dollar className="index-run-dollar" />
            <h4
              className="index-run-text"
              style={{ color: 'white', fontWeight: 'bold' }}
            >
              Prize pool {this.state.poolCal} USDT
            </h4>
          </div>
          {this.state.timeBlock !== 'Processing...' && !this.state.user && (
            <div className="d-flex justify-content-center index-run-prize-pool">
              <div
                className="text-start index-run-box-padding new-user-margin-top-main"
                style={{ color: 'white' }}
              >
                <div className="d-flex" style={{ flexDirection: 'column' }}>
                  <div
                    className="btn new-user-between"
                    style={{
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: `url('${UpPay}')`,
                      width: '100%',
                      backgroundSize: 'cover',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      className="new-user-font"
                      style={{
                        color: 'white',
                        fontWeight: '900',
                      }}
                    >
                      UP
                    </div>
                    <button
                      className="btn text-white new-user-btn"
                      style={{
                        backgroundColor: this.state.color.darkBlue,
                        borderColor: this.state.color.darkBlue,
                        width: 'max-content',
                      }}
                    >
                      <Typography className="new-user-btn-font">
                        {this.state.upMultiplier}
                        xPayout
                      </Typography>
                    </button>
                  </div>
                  <div
                    className="btn new-user-between new-user-margin-top"
                    style={{
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: `url('${DownPay}')`,
                      width: '100%',
                      backgroundSize: 'cover',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      className="new-user-font"
                      style={{
                        color: 'white',
                        fontWeight: '900',
                      }}
                    >
                      DOWN
                    </div>
                    <button
                      className="btn text-white new-user-btn"
                      style={{
                        backgroundColor: this.state.color.darkBlue,
                        borderColor: this.state.color.darkBlue,
                        width: 'max-content',
                      }}
                    >
                      <Typography className="new-user-btn-font">
                        {this.state.downMultiplier}
                        xPayout
                      </Typography>
                    </button>
                  </div>
                </div>
                <div>
                  <h5 className="index-run-text index-run-mar-top">
                    Bitcoin Locked Price (BTC)
                  </h5>
                  <h3 className="index-run-text" style={{ fontWeight: 'bold' }}>
                    {Object.keys(this.state.blockEnd).length > 0 &&
                      (this.state.blockEnd.start_price.toString().length < 10
                        ? this.addPositon(
                            this.state.blockEnd.start_price.toString(),
                            6,
                          )
                        : this.addPositon(
                            this.state.blockEnd.start_price.toString(),
                          ))}
                  </h3>
                </div>
              </div>
              <div className="index-run-up-down">
                <div
                  className="text-center index-run-up-down-padding"
                  style={{ color: 'white' }}
                >
                  <div className="d-flex">
                    <div
                      className="index-run-up-down-icon"
                      style={{
                        backgroundImage: `url('${
                          this.state.priceChange < 0 ? UpNot : Up
                        }')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'auto',
                      }}
                    ></div>
                  </div>
                  <div className="index-run-up-width">
                    <h5
                      className="index-run-up-margin-top"
                      style={{ color: 'white' }}
                    >
                      Last Price
                    </h5>
                    <h2
                      className="index-run-up-show-price"
                      style={{ color: 'white' }}
                    >
                      {parseFloat(this.state.lastPrice).toLocaleString()}
                    </h2>
                    <button
                      className="index-run-up-margin-down btn"
                      ref={(ref) => this.wrapperRef.push(ref)}
                      style={{
                        color: 'white',
                        backgroundColor: this.state.color.darkBlue,
                        borderColor: this.state.color.darkBlue,
                      }}
                    >
                      ${this.state.priceChange}
                    </button>
                  </div>
                  <div className="d-flex">
                    <div
                      className="index-run-up-down-icon"
                      style={{
                        backgroundImage: `url('${
                          this.state.priceChange > 0 ? DownNot : Down
                        }')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'auto',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.timeBlock !== 'Processing...' && this.state.user && (
            <div className="d-flex justify-content-center index-run-prize-pool">
              <div
                className="index-run-box"
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'auto',
                  backgroundImage: `url('${
                    this.state.payNowCal
                      ? this.state.payNowCal.way === 'up'
                        ? UpBox
                        : DownBox
                      : this.state.priceChange > 0
                      ? UpBox
                      : DownBox
                  }')`,
                }}
              >
                <div
                  className="text-center index-run-box-padding"
                  style={{ color: 'white' }}
                >
                  <div className="index-run-box-text-enter">
                    Entered{' '}
                    {this.state.payNowCal &&
                      (this.state.payNowCal.way === 'up' ? 'UP' : 'Down')}
                    {!this.state.payNowCal &&
                      (this.state.priceChange > 0 ? 'UP' : 'Down')}
                  </div>
                  <div
                    className="index-run-box-input"
                    style={{ backgroundColor: 'white' }}
                  >
                    <h4
                      className="index-run-text-in-input"
                      style={{ color: 'black', textAlign: 'right' }}
                    >
                      {this.state.payNowCal ? this.state.payNowCal.amount : 0}{' '}
                      USDT
                    </h4>
                  </div>
                  <button
                    className="btn text-white index-past-button"
                    style={{
                      backgroundColor: this.state.color.darkBlue,
                      borderColor: this.state.color.darkBlue,
                      width: 'max-content',
                    }}
                  >
                    {this.state.payNowCal
                      ? this.state.payNowCal.way === 'up'
                        ? this.state.payNowCal.each_round.up_multiplier
                        : this.state.payNowCal.each_round.down_multiplier.toFixed(
                            2,
                          )
                      : '1.00'}
                    xPayout
                  </button>
                  <div>
                    <h5
                      className="index-run-text index-run-mar-top"
                      style={{ textAlign: 'center' }}
                    >
                      Bitcoin Locked Price (BTC)
                    </h5>
                    <h3
                      className="index-run-text"
                      style={{ fontWeight: 'bold' }}
                    >
                      {Object.keys(this.state.blockEnd).length > 0
                        ? this.state.blockEnd.start_price.toLocaleString()
                        : null}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="index-run-up-down">
                <div
                  className="text-center index-run-up-down-padding"
                  style={{ color: 'white' }}
                >
                  <div className="d-flex">
                    <div
                      className="index-run-up-down-icon"
                      style={{
                        backgroundImage: `url('${
                          this.state.priceChange < 0 ? UpNot : Up
                        }')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'auto',
                      }}
                    ></div>
                  </div>
                  <div className="index-run-up-width">
                    <h5
                      className="index-run-up-margin-top"
                      style={{ color: 'white' }}
                    >
                      Last Price
                    </h5>
                    <h2
                      className="index-run-up-show-price"
                      style={{ color: 'white' }}
                    >
                      {parseFloat(this.state.lastPrice).toLocaleString()}
                    </h2>
                    <button
                      className="index-run-up-margin-down btn"
                      ref={(ref) => this.wrapperRef.push(ref)}
                      style={{
                        color: 'white',
                        backgroundColor: this.state.color.darkBlue,
                        borderColor: this.state.color.darkBlue,
                      }}
                    >
                      ${this.state.priceChange}
                    </button>
                  </div>
                  <div className="d-flex">
                    <div
                      className="index-run-up-down-icon"
                      style={{
                        backgroundImage: `url('${
                          this.state.priceChange > 0 ? DownNot : Down
                        }')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'auto',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.timeBlock === 'Processing...' && (
            <div className="d-flex justify-content-center index-run-prize-pool">
              <div
                className="index-run-box"
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'auto',
                  backgroundImage: `url('${
                    this.state.payNowCal
                      ? this.state.payNowCal.way === 'up'
                        ? DisableUp
                        : DisableDown
                      : this.state.priceChange > 0
                      ? DisableUp
                      : DisableDown
                  }')`,
                }}
              >
                <div
                  className="text-center index-run-box-padding"
                  style={{ color: 'white' }}
                >
                  <div className="index-run-box-text-enter">
                    {this.state.payNowCal ? (
                      <div>
                        <div>You</div>
                        <div>
                          Entered{' '}
                          {this.state.payNowCal.way === 'up' ? 'UP' : 'DOWN'}
                        </div>
                      </div>
                    ) : (
                      'Entered ' + (this.state.priceChange > 0 ? 'UP' : 'Down')
                    )}
                  </div>
                  <div
                    className="index-run-box-input"
                    style={{ backgroundColor: 'white' }}
                  >
                    <h4
                      className="index-run-text-in-input"
                      style={{ color: 'black', textAlign: 'right' }}
                    >
                      {this.state.payNowCal ? this.state.payNowCal.amount : 0}{' '}
                      USDT
                    </h4>
                  </div>
                  <div>
                    <h5
                      className="index-run-text index-run-mar-top-process"
                      style={{ textAlign: 'center' }}
                    >
                      Bitcoin Locked Price (BTC)
                    </h5>
                    <h3
                      className="index-run-text"
                      style={{ fontWeight: 'bold' }}
                    >
                      {Object.keys(this.state.blockEnd).length > 0
                        ? this.state.blockEnd.start_price.toLocaleString()
                        : null}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="d-flex justify-content-end">
            <div
              className="index-past-circle"
              style={{
                backgroundColor: this.state.color.blue,
                borderRadius: 100,
              }}
            ></div>
            <div className="index-past-round-number" style={{ color: 'white' }}>
              {this.state.blockEnd.id}
            </div>
          </div>
        </div>
      </div>
    )
  }
  showEnterState = (type) => {
    return (
      <div style={{ position: 'relative' }}>
        <div
          className="text-center"
          style={{
            position: 'absolute',
            color: 'white',
            width: '100%',
            height: '100%',
            fontWeight: '20px',
            zIndex: 9999,
          }}
        >
          <h2 className="index-entry-start">Entry starts</h2>
          <h3 className="index-entry-start-count-down">
            ~{type === 1 ? this.state.timeNext : this.state.timeNext2}
          </h3>
        </div>
        {/* <div className="card shadow" style={{ position: 'relative', height: 421.59, filter: 'grayscale(100%)  brightness(40%)' }}>
          <div className="card-body">
            <h4 style={{ color: this.state.color.font, fontWeight: 'bold' }}>Start in </h4>
            <div className="d-flex">
              <Dollar style={{ height: '30px', marginRight: '10px' }} />
              <h5 style={{ color: this.state.color.fontBlue, fontWeight: 'bold' }}>Prize pool  USDT</h5>
            </div>
            <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
              <div style={{ backgroundImage: `url('${DisableUp}')`, backgroundSize: 'cover', height: '300px', width: '240px', marginRight: '10px' }} >
                <div className="justify-content-center">
                  <div className="text-center" style={{ color: 'white', paddingTop: '60px' }}>
                    <div style={{ fontSize: '30px', lineHeight: "30px" }}>CHOOSE</div>
                    <div style={{ fontSize: '70px', fontWeight: 'bold', lineHeight: "90px" }}>UP</div>
                  </div>
                  <div className="text-center" style={{ color: 'white' }}>
                    <button className={"btn btn-primary"} style={{ marginTop: '30px', width: '200px', fontSize: '25px', backgroundColor: this.state.timeBlock === 'Processing...' ? this.state.color.already : this.state.color.darkBlue, borderColor: this.state.timeBlock === 'Processing...' ? this.state.color.already : this.state.color.darkBlue }}>{this.state.upMultiplier}xPayout</button>
                  </div>
                </div>
              </div>
              <div style={{ backgroundImage: `url('${DisableDown}')`, backgroundSize: 'cover', height: '300px', width: '240px' }} >
                <div className="justify-content-center">
                  <div className="text-center" style={{ color: 'white', paddingTop: '60px' }}>
                    <div style={{ fontSize: '30px', lineHeight: "30px" }}>CHOOSE</div>
                    <div style={{ fontSize: '70px', fontWeight: 'bold', lineHeight: "90px" }}>DOWN</div>
                  </div>
                  <div className="text-center" style={{ color: 'white' }}>
                    <button className={"btn btn-primary"} style={{ marginTop: '30px', width: '200px', fontSize: '25px', backgroundColor: this.state.timeBlock === 'Processing...' ? this.state.color.already : this.state.color.darkBlue, borderColor: this.state.timeBlock === 'Processing...' ? this.state.color.already : this.state.color.darkBlue }}>{this.state.downMultiplier}xPayout</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div
          className="card shadow index-bet"
          style={{
            filter: 'grayscale(100%)  brightness(40%)',
            backgroundColor: '#212121',
          }}
        >
          <div
            className="card-body"
            style={{ backgroundColor: '#212121', borderColor: '#2B398D' }}
          >
            <h4
              className="index-bet-start"
              style={{ color: this.state.color.font, fontWeight: 'bold' }}
            >
              Start in ??
            </h4>
            <div className="d-flex">
              <Dollar className="index-bet-dollar" />
              <h5
                className="index-bet-start"
                style={{ color: this.state.color.fontBlue, fontWeight: 'bold' }}
              >
                Prize pool 0 USDT
              </h5>
            </div>
            <div className="d-flex justify-content-center index-bet-updown-mar-top">
              <div
                className="index-bet-updown-box-up"
                style={{
                  backgroundImage: `url('${DisableUp}')`,
                  backgroundSize: 'auto',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div className="justify-content-center">
                  <div className="text-center index-bet-updown-box-mar-text">
                    <div className="index-bet-updown-box-choose">CHOOSE</div>
                    <div className="index-bet-updown-box-text">UP</div>
                  </div>
                  <div className="text-center" style={{ color: 'white' }}>
                    <button
                      className={'btn btn-primary index-bet-undown-box-button'}
                      onClick={() => {}}
                      ref={(ref) => this.wrapperRef.push(ref)}
                      style={{
                        backgroundColor:
                          this.state.timeBlock === 'Processing...'
                            ? this.state.color.already
                            : this.state.color.darkBlue,
                        borderColor:
                          this.state.timeBlock === 'Processing...'
                            ? this.state.color.already
                            : this.state.color.darkBlue,
                      }}
                    >
                      0xPayout
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="index-bet-updown-box-down"
                style={{
                  backgroundImage: `url('${DisableDown}')`,
                  backgroundSize: 'auto',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div className="justify-content-center">
                  <div className="text-center index-bet-updown-box-mar-text">
                    <div className="index-bet-updown-box-choose">CHOOSE</div>
                    <div className="index-bet-updown-box-text">DOWN</div>
                  </div>
                  <div className="text-center" style={{ color: 'white' }}>
                    <button
                      className={'btn btn-primary index-bet-undown-box-button'}
                      onClick={() => {}}
                      ref={(ref) => this.wrapperRef.push(ref)}
                      style={{
                        backgroundColor:
                          this.state.timeBlock === 'Processing...'
                            ? this.state.color.already
                            : this.state.color.darkBlue,
                        borderColor:
                          this.state.timeBlock === 'Processing...'
                            ? this.state.color.already
                            : this.state.color.darkBlue,
                      }}
                    >
                      0xPayout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    return (
      <>
        <div style={{ position: 'relative' }}>
          <img
            className="index-main-image"
            src={this.state.mobile ? MainImagePhone : MainImage}
            style={{ position: 'absolute' }}
            alt="main"
          />
          <div className="index-main-box" style={{ position: 'absolute' }}>
            <div className="index-main-title" style={{ color: 'white' }}>
              Welcome to
            </div>
            <div className="index-main-title" style={{ color: 'white' }}>
              Up Down Dinero
            </div>
            <div
              className="index-main-content index-main-margin"
              style={{
                color: 'white',
              }}
            >
              Test your luck and earn from Prediction
            </div>
            <div
              className="index-main-content"
              style={{
                color: 'white',
              }}
            >
              UP Down Dinero where financial freedom comes
            </div>
            <div
              className="index-main-content"
              style={{
                color: 'white',
              }}
            >
              with privacy and protection
            </div>
            <div>
              <Link to="/how-to-play">
                <button
                  className="btn btn-primary index-main-btn"
                  style={{ backgroundColor: '#2B398D', borderColor: '#2B398D' }}
                >
                  {'How To Play'}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="index-div-index">
          <Swiper
            navigation={this.state.mobile ? false : true}
            modules={this.state.mobile ? [] : [Navigation]}
            // initialSlide={4}
            initialSlide={4}
            spaceBetween={
              this.state.width <= 767
                ? 10
                : this.state.width > 767 && this.state.width <= 1024
                ? 20
                : this.state.width <= 1458
                ? 20
                : this.state.width <= 1682
                ? 30
                : 40
            }
            slidesPerView={
              this.state.mobile
                ? 1
                : this.state.width > 576 && this.state.width <= 767
                ? 2
                : 3
            }
            ref={this.swiperRef}
            centeredSlides={true}
            // simulateTouch={this.state.slideSwiper}
            allowTouchMove={false}
            className="index-swiper"
            // style={{ marginLeft: '20px', marginRight: '20px', marginTop: '100px' }}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={(swiper) => console.log(swiper)}
            onTouchStart={(swiper, event) => {
              if (this.wrapperRef.length > 0) {
                let outside = true
                this.wrapperRef.forEach((doc) => {
                  if (doc) {
                    if (!doc.contains(event.target)) {
                      if (outside) {
                        swiper.allowTouchMove = true
                      }
                    } else {
                      outside = false
                      swiper.allowTouchMove = false
                    }
                  }
                })
                if (outside) {
                  swiper.allowTouchMove = true
                } else {
                  swiper.allowTouchMove = false
                }
              } else {
                swiper.allowTouchMove = false
              }
            }}
          >
            {this.state.history?.length > 0 &&
              this.state.history.map((doc, key) => {
                return (
                  <SwiperSlide key={key}>{this.showStatePast(doc)}</SwiperSlide>
                )
              })}
            {this.state.history === null &&
              [1, 2, 3].map((doc, key) => {
                return (
                  <SwiperSlide key={key}>
                    {this.showStatePastNoData()}
                  </SwiperSlide>
                )
              })}
            <SwiperSlide>{this.showRun()}</SwiperSlide>
            <SwiperSlide>{this.showStateBet()}</SwiperSlide>
            <SwiperSlide>{this.showEnterState(1)}</SwiperSlide>
            <SwiperSlide>{this.showEnterState(2)}</SwiperSlide>
          </Swiper>
          <TradingViewWidget />
        </div>
      </>
    )
  }
}

export default Index
